.statics-main-sec { max-width: 90%; margin: 0px auto; }

.statics-tabs-sec { position: relative; min-height: calc(100vh - 174px); padding-left: 95px; color: #fff; position: relative; overflow: hidden;
    .blueballimg { position: absolute; right: -20px; top: 50%; transform: translateY(-50%); max-width: 100px; }
    .nav-tabs { outline: none; border: none;
        .nav-item {
            .nav-link { font-size: 16px; cursor: pointer;
                line-height: 22px;
                padding: 14px; border: 2px solid #49B9FF;
                border-radius: 16px 16px 0px 0px;background: rgba(0, 0, 0, 0.4);
                text-align: center;
                color: #FFFFFF; border-collapse: collapse; min-width: 170px;
            }
            .nav-link.active { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); }
        }
    }
    .sub-tabs-sec { overflow-x: auto; max-width: 100%;  }
    .nav-tabs.sub-tabs{ margin: 16px -16px; background: #1F1F1F; padding: 12px 24px;min-width: 900px;
        .nav-item{ margin: 0px 10px;
            .nav-link { min-width: unset;border-radius: 8px; padding: 8px 14px; cursor: pointer;
            }
        }
    }
    .nav-tabs.sub-tabs.advance-sub-tabs { min-width: 900px; }
    .statics-sec {background: rgba(0, 0, 0, 0.4);
        box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25); -webkit-box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(12px);
        border: 2px solid #49B9FF;
        padding: 18px;
        border-radius: 0px 10px 10px 10px;
        position: relative;
        z-index: 1;
        &::after { position: absolute; bottom: 0; left: 0; width: 100%; height: 100%; content: ''; backdrop-filter: blur(12px); border-radius: 0px 20px 20px 20px; }
        > div,> ul { z-index: 1; position: relative;}
        .redballimg { position: absolute; top: 98%; right: 20%; position: absolute; z-index: -1; }
        .statics-header{ display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; z-index: 9999 !important; min-height: 50px;
            h4 { margin-bottom: 0;}
            .statics-header-right { display: flex; flex-wrap: wrap;  align-items: center;
                .react-datepicker-wrapper { display: inline-block; width: auto;
                    input { background: rgba(255, 255, 255, 0.12);
                        border: 1px solid rgba(20, 121, 184, 0.8);
                        border-radius: 8px; color: #fff; padding: 10px 20px; width: 160px; text-align: right; }
                }
                span { margin: 0px 12px; }
            }
            .statics-header-middle { display: flex; align-items: center;
                a { margin: 0px 5px; }
                a#disabled{ opacity: 0.5;}
            }
            .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
                background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
            }
            .react-datepicker__header { padding-top: 20px; }
            .react-datepicker__navigation { top: 20px; }
            .react-datepicker__month { padding: 10px; }
            .react-datepicker__month-container { border-radius: 24px;}
            .react-datepicker { border-radius: 24px;}
            .react-datepicker-popper { z-index: 9999;}
            .react-datepicker__header {border-top-right-radius:24px; border-top-left-radius:24px; background: transparent;  }
        }
        .rdt_TableCol { padding: 0px 10px; }
        .blank-balls-list {  margin: 0px -3px; padding: 0px 10px;
            li {  margin: 0px 3px; background: radial-gradient(50% 50% at 50% 50%, #BEBECD 0%, #8989A4 100%); width: 24px; height: 24px; border-radius: 50%; color: #fff;letter-spacing: -0.04em;font-size: 12px;line-height: 22px; display: inline-block; }
         }
        .react-datepicker__input-container { position: relative; cursor: pointer; outline: none;
            input { outline: none; cursor: pointer; }
            &::after { position: absolute; top: 12px; left: 18px; width: 22px; height: 22px; background: url('../../images/calendar-blue.svg')no-repeat center center/cover; content: ''; }
        }
        .statics-radios-main-sec { max-width: 100%; overflow-x: auto; padding-bottom: 15px; }
        .statics-radios-sec.advance-statics-radios { min-width: 950px; }
        .statics-radios-sec { display: flex; flex-wrap: wrap;
            span { font-size: 16px;
                line-height: 22px;
                text-transform: capitalize;
                color: #FFFFFF; margin-right: 24px; }
            .radio-grp { position: relative; margin: 0px 15px;
                &::after { position: absolute; top: 3px; left: -5px; border: 2px solid #fff; border-radius: 50%; content: ''; width: 20px; height: 20px; z-index: 0; }
                label { margin-left: 15px; cursor: pointer; }
                input { width: 10px; height: 10px; background: transparent; border: none;-webkit-appearance:none; border-radius: 50%; cursor: pointer; position: relative; z-index: 1; }
                input:default { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); }
            }
            .checkbox-grp {position: relative; margin: 0px 15px;
                label { padding-left: 35px; font-weight: 400;font-size: 16px;line-height: 19px;text-transform: capitalize;color: #FFFFFF; position: relative;
                    &::before {
                        content:'';
                        -webkit-appearance: none;
                        border: 2px solid rgba(73, 185, 255, 1)
                        ;
                        backdrop-filter: blur(24px);
                        border-radius: 4px;

                        padding: 9px;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
                input:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 7px;
                    left: 5px;
                    width: 13px;
                    height: 10px;
                    background: url('../../images/tik-icon.png')no-repeat center center/cover;
                  }
                input:checked + label::before {
                background: #fff;
                }
                input { position: absolute; width: 100%; height: 100%; opacity: 0; z-index: 1; cursor: pointer; }
            }
        }
        .blue-numbers-list { margin: 0px -3px;
            .blue-number { margin: 0px 3px; background: radial-gradient(41.07% 41.07% at 44.64% 41.07%, rgba(71, 102, 255, 0.465) 0%, rgba(73, 185, 255, 0.465) 23.96%, rgba(63, 107, 255, 0.863116) 77.08%, rgba(74, 103, 248, 0.93) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #73A6FE; padding-top: 2px; width: 26px; height: 26px; border-radius: 50%; color: #fff;letter-spacing: -0.04em;font-size: 12px;line-height: 24px; display: inline-block; }
            .without-blue-number { margin: 0px 3px; width: 24px; height: 24px; border-radius: 50%; color: #fff;letter-spacing: -0.04em;font-size: 16px;line-height: 22px; display: inline-block; }
        }
        .frequancy-column { display: flex; flex-wrap: wrap; align-items: center;
            .frequancy-bar { background: #DEE1FF; margin-left: 10px;
                border-radius: 8px; width: 180px; height: 4px; position: relative;
                .friquancy-value { height: 4px;  border-radius: 8px;background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);display: inline-block;position: absolute; top: 0; left: 0; }
            }
        }
        .last-draw-list {
            li { margin-top: 0; margin-bottom: 0; padding-right: 3px; width: 28px; height: 28px; transform: none; border-radius: 50%;background: radial-gradient(41.07% 41.07% at 44.64% 41.07%, rgba(255, 255, 255, 0.3348) 0%, rgba(220, 212, 212, 0.120319) 23.96%, rgba(0, 0, 0, 0.4278) 100%) , #FFFFFF; color: #000A3D; line-height: 28px;  }
        }
    }
}
.settings-popup {
    .modal-content  { min-height: unset; padding: 20px; background: rgba(0, 0, 0, 1); text-align: center; border: 2px solid #49b9ff; border-radius: 20px;
        h3 { margin-bottom: 20px;}
        .common-white-btn,
        .common-blue-btn {  min-width: unset; margin: 0px 10px; }
        .settings-btn-grp { margin-top: 20px; }
    }
 }
 .statics-sec.my-games-sec { padding-bottom: 5px !important; }
 .statics-sec.my-games-sec .common-table-sec .rdt_TableBody {
    > div { min-height: 38px; }
 }
 .preview-popup { max-width: 1100px !important; width: 100%;
    .modal-content  { padding: 20px; background: rgba(0, 0, 0, 1); text-align: center; border: 2px solid #49b9ff; border-radius: 20px;max-height: 80vh; overflow-y: auto; z-index: 999;
        .played-options { font-size: 14px; line-height: 22px; display: flex; flex-wrap: wrap; text-transform: uppercase; color: #fff; font-weight: 600; align-items: center;
            ul { margin-bottom: 0; margin-left: 8px; display: flex; flex-wrap: wrap; list-style-type: none; padding: 0;
                li {  margin: 0px 10px;
                  > span {transform: rotate(45deg);background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); border-radius: 4px; width: 25px; height: 25px; display: flex; align-items: center; justify-content: center; font-size: 12px; line-height: 18px; font-weight: 700;
                   > span { display: inline-block; transform: rotate(-45deg); }
                   }
                }
            }
        }
        .preview-header-sec{ margin-top: 25px;
            ul { list-style-type: none; display: flex; flex-wrap: wrap; margin: 0px -8px; width: 100%; padding: 0;
                li {background: #1F1F1F; width: calc(100% / 3 - 16px); text-align: left; padding: 16px 24px;
                    border-radius: 8px; margin: 0px 8px;
                    p { font-weight: 500;
                        font-size: 14px;
                        line-height: 20px; margin-bottom: 6px;
                        .tooltip-heading { display: inline-block; margin-left: 5px; }
                    }
                    h3 {     background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;margin-bottom: 0; }
                }
            }
        }
        .preview-details-sec { margin-top: 25px; display: flex; flex-wrap: wrap;
            .preview-detail-left { width: calc(100% / 3);
                ul { list-style-type: none; color: #fff; padding: 0; margin: 0;
                    li { padding-left: 40px;font-size: 14px;
                        line-height: 18px;
                        text-transform: capitalize; position: relative; margin-bottom: 15px; text-align: left;
                        span { position: absolute; width: 16px; height: 16px; border-radius: 50%; content: '';  content: ''; top: 0; left: 10px; }
                        &:last-of-type { margin-bottom: 0; }
                    }
                }
            }
            .preview-details-right { flex-grow: 1; }
         }
         .nav-tabs { justify-content: flex-end; border: none; margin-top: -55px;
            .nav-item {
                .nav-link { background: rgba(0, 0, 0, 0.4); font-weight: 700;
                    border-radius: 14px 14px 0px 0px;padding: 12px 32px; color: #999999;border: 2px solid #49B9FF; cursor: pointer;
                }
                .nav-link.active { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); color: #fff;  }
            }
         }
         .tab-chart-sec {
            padding: 10px !important; border: 2px solid #49B9FF; border-radius: 20px 0px 20px 20px;
         }
        ul.preview-header-list { list-style-type: none; color: #fff; padding: 0; margin: 0; display: flex; flex-wrap: wrap; margin-top: 25px;
            li { padding-left: 40px;font-size: 16px; margin-right: 30px;
                line-height: 20px;
                text-transform: capitalize; position: relative; text-align: left;
                span { position: absolute; width: 16px; height: 16px; border-radius: 50%; content: '';  content: ''; top: 3px; left: 10px; }
                &:last-of-type { margin-right: 0;}
            }
        }
    }

 }
 .modal-content::-webkit-scrollbar-track { margin: 20px 0px; }
 .nori-evation-popup.preview-popup {
    .preview-detail-left { width: auto !important; margin-right: 20px; }
 }
 .statics-range { margin-top: 30px; margin-bottom: 50px; max-width: 97%; }
 .canvasjs-chart-container { position: relative !important; top: 0 !important; left: 0 !important; }
 .statics-sec  {
    .common-table-sec{
        .rdt_TableBody { max-height: 580px; overflow-y: auto; }
    }
 }
 .rdt_TableHeadRow span { opacity: 1; }
 .react-datepicker__day--outside-month { opacity: 0; visibility: hidden; }
 .settings-sec { position: relative;
        .apply-seetings-popup  { display: none; position: absolute !important;top: 0; left: -18px; width: calc(100% + 36px); height: calc(100% + 18px); border-radius: 20px; background: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(2px); z-index: 120;
            .modal-content { max-width: 540px; margin: 0px auto; top: 60px;
                .common-close-btn { position: absolute; }
            }
        }
        .apply-seetings-popup.active { display: block; }
 }
 .preview-popup .modal-content .tab-chart-sec.p-fact-chart-sec { padding: 45px 20px 45px 70px !important;  position: relative;
    .p-fact-vertical-title { position: absolute; top: 50%; transform: translateY(-50%) rotate(-90deg); left: 5px;  font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FFFFFF;
        opacity: 0.5; }
    .p-fact-horizon-title {position: absolute; bottom: 15px; left: 50%; transform: translateX(-50%); font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FFFFFF;
        opacity: 0.5;  }
 }
.unit-radio-grp         { min-height: 100px; position: relative; }
.rdt_TableCol_Sortable span { margin-left: 10px; }

@media (min-width:1200px){
    .statics-tabs-sec{
        .nav-tabs.sub-tabs { max-width: 100%; }
    }
}
 @media(min-width:992px) and (max-width:1199px){
    .preview-popup  { max-width: 900px !important;
        .modal-content {
            .preview-header-sec {
                ul {
                    li { padding: 10px 15px;}
                }
            }
            .common-title { margin-bottom: 20px; }
        }
    }
    .statics-tabs-sec .statics-sec .frequancy-column { flex-wrap: nowrap;}
    .statics-tabs-sec .statics-sec .rdt_TableCol { min-width: unset; }
    .statics-tabs-sec{ overflow: unset;
        .blueballimg { right: 0; }
        .statics-sec { width: 100%; max-width: 100%;
            .statics-header { justify-content: flex-start;
                .statics-header-middle { margin-left: 10px;}
                .statics-header-right { margin-top: 20px; width: 100%; }
            }
        }
    }
    .statics-tabs-sec .statics-sec.my-games-sec .statics-header { justify-content: space-between !important;  }
 }
 @media(min-width:768px) and (max-width:991px){
    .preview-popup  { max-width: 700px !important;
        .modal-content {
            .preview-header-sec {
                ul {
                    li { padding: 10px 15px;}
                }
            }
            .preview-details-sec {
                .preview-detail-left {
                    ul {margin-top: 0px; }
                 }
            }
            .common-title { margin-bottom: 20px; }
            .nav-tabs { margin-top: 0; }
        }
    }
    .common-table-sec ul li { margin: 10px 5px;  }
    .statics-tabs-sec .statics-sec .frequancy-column { flex-wrap: nowrap; min-width: 250px;}
    .statics-tabs-sec .statics-sec .rdt_TableCol { min-width: unset; }
    .nori-evation-popup.preview-popup .preview-detail-left { margin-top: 50px; }
    .statics-main-sec { max-width: 100%; margin-top: 25px; }
    .statics-sec { width: 100%; max-width: 95% !important; padding: 20px !important;
        .statics-header { justify-content: flex-start !important;
            .statics-header-middle { margin-left: 30px;}
            .statics-header-right { margin-top: 20px; width: 100%; }
        }
        .mygames-header.statics-header { justify-content: space-between !important;}
        &::after { backdrop-filter: blur(6px) !important;}
        .statics-radios-sec { margin-top: 20px;}
    }
    .statics-tabs-sec {
        .nav-tabs.sub-tabs.advance-sub-tabs { padding: 10px 16px; min-width: 760px;  }
        .nav-tabs.sub-tabs.advance-sub-tabs.statics-first-tabs { min-width: 730px; }
        .nav-tabs{
            .nav-item{
                .nav-link { font-size: 12px; line-height: 20px; min-width: 130px; }
            }
        }
        .statics-radios-sec { padding-bottom: 5px; margin-top: 20px; align-items: center;
            span,p { font-size: 12px !important; margin-right: 10px !important; }
            span {  margin-top: 10px; font-size: 12px !important; }
            label { font-size: 16px;}
            .radio-grp,
            .checkbox-grp { margin-top: 10px !important; }
        }
        .statics-radios-sec.primes-radio-sec { min-width: 515px; }
        .statics-radios-sec.distance-radio  { min-width: 830px !important; }
        .statics-radios-sec.unit-radio-grp  { min-width: 470px !important; }
        .statics-radios-sec.unit-second-radio-grp { min-width: 650px; }
        .statics-radios-sec.event-number-radio-grp { min-width: 740px; }
        .statics-radios-sec.draw-radio-grp { min-width: 730px; }
        .statics-radios-sec.undrawn-radio-sec { min-width: 700px; }
        .statics-radios-sec.neighborhood-radio-sec { min-width: 650px; }
    }
    .statics-tabs-sec .statics-sec.my-games-sec .statics-header { justify-content: space-between !important;  }
 }
 @media(max-width:767px){
    .statics-tabs-sec .statics-sec.my-games-sec .statics-header { justify-content: center !important; text-align: center; justify-content: center; flex-direction: column; align-items: center;
        h4 {  margin-bottom: 30px; text-align: center; justify-content: center; }
    }
    .preview-popup  { max-width: 85% !important;
        .modal-content { max-height: 75vh; overflow-y: auto;
            .common-title { margin-bottom: 20px; }
            .preview-header-sec {
                ul { margin: 0;
                    li { padding: 10px 15px; width: 100%; margin: 0; margin-bottom: 20px; }
                }
            }
            .preview-details-main-sec { overflow-x: auto; padding-top:15px; }
            .preview-details-sec{ margin-top: 10px; min-width: 800px;
                .preview-detail-left { width: 100%;
                    ul { margin-top: 40px; }
                }
                .preview-detail-left.use-nori-left-preview { width: 20%;
                    ul { margin-top: 0; }
                }
                .preview-detail-right { }
            }
            .preview-header-list {
                flex-direction: column;
                li {margin-right: 0; margin-bottom: 15px;
                    &:last-of-type { margin-bottom: 15px; }
                }
            }
            .preview-detail-left { padding: 0 !important; }
            .nav-tabs { margin-top: 0;
                .nav-item {
                    .nav-link { padding: 10px 20px; font-size: 12px; }
                }
            }
        }
    }
    .statics-main-sec { max-width: 100%; margin-top: 25px; }
    .my-games-main-sec { margin-bottom: 30px; }
    .statics-sec { width: 100%; max-width: 100% !important;  padding: 20px !important;
        .statics-header { justify-content: flex-start !important;
            .statics-header-middle { margin-left: 0px; margin-top: 20px; width: 100%; font-size: 12px; }
            .statics-header-right { margin-top: 10px; width: 100%;
                .react-datepicker-wrapper{
                    input { width: 110px !important; padding: 7px 14px !important; font-size: 12px; }

                }
                span { margin: 0px 5px !important; }
            }
        }
        .mygames-header.statics-header { justify-content: space-between !important;}
        .react-datepicker__input-container {
            &::after { top: 9px !important; left: 8px !important; width: 15px !important; height: 15px !important; }
        }
        &::after { backdrop-filter: blur(6px) !important;}
        .statics-range { font-size: 12px; margin-bottom: 30px; max-width: 100%; }
    }
    .statics-tabs-sec { padding: 0px 20px;
        .nav-tabs.sub-tabs.advance-sub-tabs { padding: 10px 16px; min-width: 760px;  }
        .nav-tabs.sub-tabs.advance-sub-tabs.statics-first-tabs { min-width: 730px; }
        .nav-tabs{
            .nav-item{
                .nav-link { font-size: 12px; line-height: 20px; min-width: 130px; }
            }
        }
        .statics-radios-sec { padding-bottom: 5px; margin-top: 20px; align-items: center;
            span,p { font-size: 12px !important; margin-right: 10px !important; }
            span {  margin-top: 10px; font-size: 12px !important; }
            label { font-size: 16px;}
            .radio-grp,
            .checkbox-grp { margin-top: 10px !important; }
            .tooltiptext  { right: unset !important; left: 0 !important; }
        }
        .statics-radios-sec.primes-radio-sec { min-width: 515px; }
        .statics-radios-sec.distance-radio  { min-width: 830px !important; }
        .statics-radios-sec.unit-radio-grp  { min-width: 470px !important; }
        .statics-radios-sec.unit-second-radio-grp { min-width: 650px; }
        .statics-radios-sec.event-number-radio-grp { min-width: 740px; }
        .statics-radios-sec.draw-radio-grp { min-width: 730px; }
        .statics-radios-sec.undrawn-radio-sec { min-width: 700px; }
        .statics-radios-sec.neighborhood-radio-sec { min-width: 650px; }
    }
    .statics-tabs-sec{
        .statics-sec{
            .frequancy-column { flex-wrap: nowrap; }
            .statics-radios-sec {
                .tooltiptext  { right: 0; left: unset; min-width: 200px; }
             }
        }
    }
    .apply-seetings-popup {
        .modal-content { max-width: 90% !important; }
    }
    .unit-radio-grp         { min-height: 126px; }
 }
 .preview-popup .modal-content .played-options ul { margin-top: 20px;
    li {margin-bottom: 15px; }
}
@media(min-width:320px) and (max-width:1199px){
    // .range-slider-sec .rangehandle:first-of-type { transform: translateX(0) !important; }
}