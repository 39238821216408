nav.navbar {
    background: url('../../images/header-bg.png')no-repeat center center/cover;
    padding: 11px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9998;

    .navbar-nav {
        margin-left: auto;
        cursor: pointer;

        .nav-item {
            margin: 0px 20px;

            a {
                color: #fff;
                letter-spacing: -0.01em;
                color: #999999;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                position: relative;
                transition: 0.3s;

                &:before {
                    position: absolute;
                    top: 105%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(90deg, rgba(73, 185, 255, 0) 0%, #49B9FF 29.17%, #3F5DFF 71.35%, rgba(63, 93, 255, 0) 100%);
                    border-radius: 8px;
                    content: '';
                    max-width: 0;
                    transition: 0.3s;
                }

                &:hover {
                    color: #fff;
                    transition: 0.3s;

                    &::before {
                        max-width: 100%;
                        transition: 0.3s;
                    }
                }
            }

            a.dropdown-toggle {
                &::after {
                    content: '\f107';
                    font-family: "FontAwesome";
                    border: none;
                    font-size: 16px;
                    line-height: 16px;
                    vertical-align: middle;
                    color: rgba(76, 76, 76, 1);
                }
            }

            .dropdown {
                height: 100%;
                display: flex;
                z-index: 10;

                >button {
                    width: 100px;
                    height: 100%;
                    font-size: 18px;
                    border-radius: 50px;
                    background: #a2a2cf;
                    background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 10px 7px;
                    gap: 8px;

                    >div {
                        display: flex;
                        text-transform: uppercase;
                        align-items: center;
                        justify-content: space-around;
                    }

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }


                    FaCaretDown {
                        color: #ffffff;
                    }


                }

                ul {
                    position: absolute;
                    top: 50px;
                    right: -10px;
                    width: 200px;
                    padding: 10px 0;
                    margin: 0;
                    border-radius: 10px;
                    list-style-type: none;
                    background-color: white;
                    max-height: calc(100vh - 75px);

                    li {

                        button {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            padding: 3px 10px;
                            font-size: 18px;
                            background-color: transparent;
                            position: relative;
                            border: none;
                            border-bottom: 1px solid gray;

                            img {
                                margin-right: 20px;
                                width: 20px;
                            }

                            strong {
                                position: absolute;
                                right: 5px;
                            }
                        }

                        &:last-child button {
                            border: none;
                        }

                        &:hover {
                            background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 50%, #49B9FF 100%);
                        }
                    }
                }

            }
        }

        .nav-item.active {
            a {
                color: #fff;
                transition: 0.3s;

                &::before {
                    max-width: 100%;
                    transition: 0.3s;
                }
            }
        }

        .nav-item.connect-wallte-btn {
            a {
                background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                border-radius: 64px;
                padding: 10px 24px;
                color: #fff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                &:hover {
                    &::before {
                        max-width: 0;
                    }
                }

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

.navbar-brand {
    cursor: pointer;
}
.bnbLogo{
    margin-right: 10px;
}
.chainlink-navbar-logo {
    display: flex;
    justify-content: center;
    a {
        width: 80%;
        max-width: 200px;
        img {
            width: 80%;
            min-width: 95px;
        }
    }
}
.chainlink-logo {
    display: block;
}
.chainlink-smalllogo {
    display: none;
}
@media(min-width:1200px) and (max-width:1455px) {
    .navbar-brand {
        img {
            max-width: 200px;
        }
    }
    .chainlink-logo {
        display: block;
    }
    .chainlink-smalllogo {
        display: none;
    }

    nav.navbar {
        .navbar-nav {
            .nav-item {
                margin: 0px 10px;

                a {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}
@media(min-width:981px) and (max-width:1200px) {
    .chainlink-navbar-logo {
        display: flex;
        justify-content: center;
        a {
            width: 100%;
            img {
                width: 40px;
                min-width: 40px;
            }
        }
    }
    .chainlink-logo {
        display: none;
    }
    .chainlink-smalllogo {
        display: block;
    }
    nav.navbar {
        .navbar-nav {
            align-items: center;

            .nav-item {
                margin: 0px 10px;

                a {
                    font-size: 12px;
                    line-height: 20px;
                    padding: 0;
                }
            }
        }
    }

}
@media(min-width:768px) and (max-width:980px) {
    .navbar-brand {
        img {
            max-width: 170px;
        }
    }
    .chainlink-navbar-logo {
        display: flex;
        justify-content: center;
        a {
            width: 100%;
            img {
                width: 40px;
                min-width: 40px;
            }
        }
    }
    .chainlink-logo {
        display: none;
    }
    .chainlink-smalllogo {
        display: block;
    }

    nav.navbar {
        .navbar-nav {
            align-items: center;

            .nav-item {
                margin: 0px 10px;

                a {
                    font-size: 12px;
                    line-height: 20px;
                    padding: 0;
                }
            }
        }
    }
}

@media(max-width:767px) {
    .navbar-brand {
        img {
            max-width: 170px;
        }
    }
    .chainlink-navbar-logo {
        display: flex;
        justify-content: center;
        a {
            width: 100%;
            img {
                width: 60%;
                min-width: 40px;
            }
        }
    }
    .chainlink-logo {
        display: block;
    }
    .chainlink-smalllogo {
        display: none;
    }
    nav.navbar {
        .navbar-nav {
            .nav-item {
                margin: 0px 10px;

                a {
                    font-size: 14px;
                    line-height: 20px;
                }

                .dropdown {
                    align-items: center;
                    flex-direction: column;

                    ul {
                        background: linear-gradient(90deg, #eaeaea65 0%, #eaeaeace 50%, #eaeaea65 100%);
                        position: relative;
                        top: 10px;
                        right: 0;
                    }
                }
            }
        }
    }

    .navbar {
        .container-fluid {
            padding: 0;
        }
    }

    .navbar {
        .navbar-toggler {
            padding: 0;
            border: none;
            outline: none;
            width: 30px;
            height: 30px;
            display: flex;
            flex-wrap: wrap;
            box-shadow: none;

            span {
                margin-bottom: 5px;
                background-color: #fff;
                width: 100%;
                height: 4px;
                border-radius: 5px;
                transform-origin: center center;
                position: relative;
                transition-duration: 0.5s;
                display: inline-block;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .navbar.toggle-btn-open {
        span {
            background-color: #fff;
        }

        span:first-child {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 8px;
        }

        span:nth-child(2) {
            opacity: 0;
        }

        span:last-child {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -11px;
        }
    }

    .navbar-collapse {
        margin: 0px -15px;
        background: rgba(25, 25, 25, 0.8);
        padding: 30px 20px;

        .navbar-nav {
            text-align: center;

            li.nav-item {
                margin: 0;
                margin-bottom: 10px;
                display: inline-block;

                a {
                    display: inline-block;

                    &::before {
                        width: 150%;
                        left: -25%;
                    }

                    &:hover::before {
                        max-width: 150%;
                    }
                }
            }

            li.nav-item.active {
                a {
                    &::before {
                        max-width: 150%;
                    }
                }
            }
        }
    }
}
@media(max-height:600px) {
    .dropdown ul {
        overflow-y: scroll;
    }
}
@media(max-width:455px) {
    .chainlink-navbar-logo {
        display: flex;
        justify-content: center;
        a {
            width: 100%;
            img {
                width: 30px;
                min-width: 30px;
            }
        }
    }
    .chainlink-logo {
        display: none;
    }
    .chainlink-smalllogo {
        display: block;
    }
}
@media(max-width: 350px) {
    .chainlink-logo {
        display: none;
    }
    .chainlink-smalllogo {
        display: none;
    } 
}
