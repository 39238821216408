.tutorial,
.help {
    color: white;
    min-height: calc(100vh - 174px);
    max-width: 950px;
    margin: 0 auto;
    padding: 1%;

    h1 {
        color: #3F5DFF;
    }

}

.help {
    font-size: 20px;
}

.illustrated {
    display: flex;
    position: relative;

    >* {
        width: 50%;
        padding: 10px;
        position: relative;
    }

    >img {
        height: 100%;
    }

    >div img {
        width: 100%;
        object-fit: cover;
    }

}

.accordeon {

    input {
        display: none;
    }

    label {
        background: linear-gradient(133.31deg, #2b74a1 0%, #3751e2 50%, #2b74a1 100%);
        border-radius: 15px;
        display: flex;
        width: 100%;
        min-height: 50px;
        cursor: pointer;
        border-bottom: 3px solid #848a8f;
        user-select: none;

    }

    .content label {
        background: linear-gradient(133.31deg, #2949ff 0%, #0f1b5f 50%, #2949ff 100%);
    }

    .content content label {
        background: linear-gradient(133.31deg, #0f1b5f 0%, #050c35 50%, #0f1b5f 100%);
    }

    label div:first-child {
        width: 100%;
        line-height: 45px;
        margin-left: 10px;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
    }

    .cross {
        margin-right: 15px;
        margin-top: 3px;
    }

    .cross:before,
    .cross:after {
        content: '';
        border-top: 4px solid #ffffff;
        width: 15px;
        display: block;
        margin-top: 18px;
        transition: 0.3s;
    }

    .cross:after {
        transform: rotate(90deg);
        margin-top: -4px;
    }

    .content {
        box-sizing: border-box;
        font-size: 16px;
        margin: 10px 10px;
        max-height: 0;
        overflow: auto;
        transition: max-height, .5s;
        position: relative;
    }

    input:checked~.content {
        max-height: 40000px;
        transition: max-height, 1s;
    }

    input:checked~label .cross:before {
        transform: rotate(180deg);
    }

    input:checked~label .cross:after {
        transform: rotate(0deg);
    }

    .questions {
        margin-top: 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height, .5s;
    }

    .questions label {
        border: none;
        box-shadow: none;
        margin: 0;
    }

    input:checked~.questions {
        max-height: 40000px;
        transition: 1s;
    }

    ol {
        margin-left: 5px;
    }

    li {
        color: #9d9d9d;
        padding-left: 30px;
        margin-top: 24px;
        position: relative;
        font-size: 16px;
        line-height: 20px;

        &:before {
            content: '';
            display: block;
            height: 42px;
            width: 42px;
            border-radius: 50%;
            border: 2px solid #ddd;
            position: absolute; // yeah, you have to manually position it based on the text size, line height, etc. (in the parent li style). it's not the smarest solution 
            top: -10px;
            left: -33px;
        }

        p {
            color: white;
        }
    }

    strong {
        color: #9d9d9d;
        font-size: 25px;
    }

    ol.alternating-colors {
        li:nth-child(odd):before {
            border-color: #0BAD02;
        }

        li:nth-child(even):before {
            border-color: #2378D5;
        }
    }
}


.tutorials-list-sec {
    max-width: 950px;
    margin: 50px auto;

    .tutorial-list-item {
        margin-bottom: 80px;
        text-align: center;

        h1 {
            background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            letter-spacing: 0.02em;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        .tutorial-list-img {
            position: relative;

            a {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    p {
        color: #fff;
        text-align: center;
        letter-spacing: -0.01em;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;

        a {
            color: #2560E9;
            text-decoration: underline;
        }
    }
}

@media (min-width:768px) and (max-width:991px) {
    .tutorials-list-sec {
        max-width: 90%;

        img,
        .img {
            max-width: 100%;
        }
    }
}

@media(max-width:767px) {
    .tutorials-list-sec {
        max-width: 90%;

        .tutorial-list-item {
            margin-bottom: 30px;

            img {
                max-width: 100%;
            }
        }

        p {
            font-size: 14px;
        }
    }
}

@media(max-width:767px) {
    .illustrated {
        flex-direction: column;
        border-bottom: 1px solid gray;

        &:last-child {
            border: none;
        }

        &:nth-child(odd) {
            flex-direction: column-reverse;
        }

        >* {
            width: 100%;
        }
    }
}

.videos {
    min-height: calc(100vh - 125px);
    .item {
        display: flex;
        &>h1{
            width: 50%;
            text-align: right;
        }
        &>div{
            width: 50%;
        }
        &:nth-child(even){
            flex-direction: row-reverse;
            & >h1{
                text-align: left;
            }
        }
    }
    h1 {
        color: white;
        background-color: transparent;
        margin-top: 50px;
    }

    .frame {
        padding: 2%;
        cursor: pointer;
        position: relative;
        display: inline-block;
        max-width: 400px;
        border-radius: 8px;
        .play{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

  .preview-image {
    width: 100%;
    max-width: 400px;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
  
.play {
    width: 50px;
    aspect-ratio: 1;
    color: #2378D5;
    background: currentColor;
    border-radius: 50%;
    position: relative;
  }
  .play:before {
    content: "";
    position: absolute;
    background:
      radial-gradient(farthest-side at bottom right,#0000 94%,currentColor 96%) 0    0   ,
      radial-gradient(farthest-side at bottom left ,#0000 94%,currentColor 96%) 100% 0   ,
      radial-gradient(farthest-side at top    left ,#0000 94%,currentColor 96%) 100% 100%,
      radial-gradient(farthest-side at top    right,#0000 94%,currentColor 96%) 0    100%;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    animation: 
      l39-1 2s infinite,
      l39-2 2s infinite;
  }
  .play::after {
    content: "\25B7";
    color: white;
    position: absolute;
    font-size: 30px;
    top: 50%;
    left: 50%;
    transform: translateX(-40%) translateY(-50%);
  }
  @keyframes l39-1 {
    0%,10%,90%,100% {inset:0}
    40%,60% {inset:-10px}
  }
  
  @keyframes l39-2 {
    0%,40%  {transform: rotate(0)}
    60%,100%{transform: rotate(90deg)}
  }
  
}
.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding-top: 98px;
  
  .modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 16/9;
  }
  
  .close-modal {
    position: absolute;
    width: 40px;
    aspect-ratio: 1/1;
    top: 50px;
    right: 4%;
    border-radius: 50px;
    background-color: #e02929;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;

}
}
iframe {
    border: 1px solid #3F5DFF;
    border-radius: 15px;
    height: 90%;
    width: 90%;
    object-fit: contain;
}
@media(max-width:767px) {
    .item{
        word-break: 100%;
        flex-direction: column !important;
        &:nth-child(even){
            flex-direction: column;
        }
        &> h1{
            width: 100% !important;
            text-align: center !important;
        }
        &> div{
            width: 100% !important;
            display: flex !important;
            justify-content: center !important;
        }
    }
}