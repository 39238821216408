.home-footer-sec.visibleBall {
    .whiteballimg {
        display: block;
    }
}

.home-footer-sec {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .whiteballimg {
        position: absolute;
        top: -56px;
        left: 5px;
        display: none;
    }

    .winning-number-sec {
        width: 40%;
        background: #191919;
        text-align: center;

        .winning-number-top {
            margin-bottom: 35px;
            position: relative;
            max-width: 100%;
            margin: 0px auto;
            background: #2a2929;
            padding: 22px 0px;


            // &::after { position: absolute; top: calc(100% + 15px); left: 0; width: 100%; height: 2px; content: '';background: linear-gradient(145deg, rgba(73, 185, 255, 0) 0%, #49b9ff 29.17%, #3f5dff 71.35%, rgba(63, 93, 255, 0) 100% ); }
        }

        .winning-number-bottom {
            padding: 20px 0px;

            .privous-number {
                margin-top: 20px !important;
            }
        }

        h3 {
            font-weight: 700;
            margin-bottom: 10px;
            color: #4C9CF2;
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
        }

        p {
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            margin-bottom: 5px;
            font-size: 16px;
        }

        // .wunning-number-header { margin-top: 20px; }
        .winningnumbersdateslider {
            max-width: 400px;
            margin: 0px auto;
        }

        .winningnumbersslider {
            text-align: center;
            margin-bottom: 10px;
            text-align: center;
            max-width: 400px;
            margin: 0px auto;

            .slick-list {
                padding: 10px 0px;
            }

            .slick-slide {
                text-align: center;
                display: flex;
                justify-content: center;
                // > div { position: relative;
                //     &::after { position: absolute; top: -5px; left: -5px; width: calc(100% + 10px); height: calc(100% + 10px); content: ''; background: #355eba; border-radius: 4px;  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                //         clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%); z-index: 0; }
                // } }
            }

            .privous-number {
                position: relative;
                z-index: 1;
                width: 64px !important;
                height: 64px;
                background: url('../../images/polygon-img.svg')no-repeat center center/100% 100%;
                border-radius: 4px;
                float: left;
                display: flex !important;
                align-items: center !important;
                justify-content: center;
                align-items: center;
                border-radius: 7.11111px;
                margin: 0;

                h3 {
                    margin: 0 !important;
                    font-weight: 700;
                    color: #000A3D;
                    background: transparent;
                    -webkit-background-clip: unset;
                    -webkit-text-fill-color: unset;
                    display: flex;
                    align-items: center;

                    span {
                        letter-spacing: -0.01em;
                    }
                }

                label {
                    position: absolute;
                    bottom: 110%;
                    left: 0;
                    font-size: 12px;
                    text-transform: uppercase;
                    line-height: 12px;
                    color: #8CCDFF;
                    width: 100%;
                    text-align: center;
                }

            }

        }

        .winningnumbersdateslider {
            .slick-arrow.slick-next {
                opacity: 0;
                visibility: hidden;
            }

            .slick-arrow.slick-prev {
                opacity: 0;
                visibility: hidden;
            }
        }

        .slick-arrow.slick-prev {
            bottom: -75px;
            left: 60px;
            top: unset;
            background: url('../../images/arrow-left.png')no-repeat center center/100% auto;

            &::before {
                content: '';
            }
        }

        .slick-arrow.slick-next {
            bottom: -75px;
            right: 60px;
            top: unset;
            background: url('../../images/arrow-right.png')no-repeat center center/100% auto;

            &::before {
                content: '';
            }
        }

        .prev-number-label {
            p {
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                display: inline-block;
                margin: 0px 10px;
            }

            button {
                background: transparent;
                padding: 0;
                box-shadow: none;
                border: none;

                img {
                    transform: scale(1);
                    transition: 0.4s;
                }

                &:hover img {
                    transform: scale(1.2);
                    transition: 0.4s;
                }
            }

            button.disabled {
                opacity: 0.4;
            }
        }
    }

    .chart-section {
        width: 60%;
        padding: 24px 30px;
        background: #242424;
        text-align: center;
        position: relative;

        p {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 30px;
            color: #fff;
        }

        .search-btn {
            position: absolute;
            top: 20px;
            right: 30px;
            background: transparent;
            box-shadow: none;
            outline: none;
            border: none;
            z-index: 99;
        }
    }
}

.chart-modal {
    max-width: 90% !important;
    display: inline-block;
    min-width: 1100px;
    overflow: hidden;

    .modal-content {
        padding: 50px 30px 50px 60px;
        background: rgba(0, 0, 0, 1);
        border: 2px solid #49b9ff;
        border-radius: 20px;

        .chart-inner-sec {
            &:after {
                height: 20px;
                left: -20px;
                bottom: 41px;
                width: 100px;
                opacity: 0;
            }
        }

        .common-close-btn {
            z-index: 99;
        }

        .chart-numbers {
            z-index: 98;
            text-align: center;
            margin-bottom: 0;
            position: relative;
        }
    }

    .canvasjs-chart-credit {
        display: none;
    }

    .graph-vertical-title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        left: 0px;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FFFFFF;
        opacity: 0.5;
    }

    .graph-horizontal-title {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FFFFFF;
        opacity: 0.5;
    }
}

// .canvasjs-chart-credit { display: none; }
@media(min-width:992px) and (max-width:1199px) {
    .home-footer-sec {
        .winning-number-sec {
            width: 100%;
            margin-top: 0;

            .wunning-number-header {
                margin-top: 0px;
            }
        }

        .chart-section {
            padding: 30px 40px;
            width: 100%;
        }

        .whiteballimg {
            left: -30px;
        }
    }

    .chart-modal {
        min-width: 900px;

    }
}

@media(min-width:768px) and (max-width:991px) {
    .home-footer-sec {
        .winning-number-sec {
            width: 100%;
            margin-top: 0;

            .wunning-number-header {
                margin-top: 0px;
            }
        }

        .chart-section {
            padding: 20px 40px;
            width: 100%;

            .search-btn {
                display: none;
            }
        }

        .whiteballimg {
            left: -30px;
        }
    }

    .chart-modal {
        min-width: 700px;
    }
}

@media(max-width:767px) {
    .home-footer-sec {
        .winning-number-sec {
            width: 100%;
            margin-top: 0;
            overflow: hidden;

            .wunning-number-header {
                margin-top: 0px;
            }

            .slick-arrow.slick-prev {
                left: -20px;
            }

            .slick-arrow.slick-next {
                right: -20px;
            }

            .winningnumbersslider {
                .slick-slide>div>div {
                    display: flex !important;
                    justify-content: center;
                }

                .privous-number {
                    width: 40px !important;
                    height: 40px !important;
                    font-size: 20px;

                    h3 {
                        span {
                            font-size: 17px;
                        }
                    }
                }
            }
        }

        .chart-section {
            padding: 60px 20px 20px;
            width: 100%;

            .search-btn {
                display: none;
            }
        }

        .whiteballimg {
            left: -10px;
            max-width: 80px;
        }
    }

    .prev-number-label {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .chart-modal {
        min-width: unset;
        width: 90% !important;
        z-index: 99;

        .modal-content {
            padding: 55px 10px 50px 40px;
        }

        .graph-vertical-title {
            left: -10px;
        }

        .graph-horizontal-title {
            bottom: 0px;
        }
    }

    .home-footer-sec .winning-number-sec .winningnumbersslider .privous-number label {
        font-size: 9px;
    }
}

@media(min-width:320px) and (max-width:360px) {
    .steps ul li div {
        width: 20px !important;
        height: 20px !important;

        span {
            font-size: 12px !important;
        }
    }

}

.graph-container {
    .modal-dialog {
        width: 1000px;

        .modal-content {
            width: 1000px;

            .modal-body {
                width: 1000px;
            }
        }
    }
}