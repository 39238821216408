.live-drawing-sec { padding: 40px 0px; padding-left: 80px; color: #fff; text-align: center; position: relative; min-height: calc(100vh - 174px); overflow: hidden;
    .blueballimg { position: absolute; right: -20px; top: 50%; transform: translateY(-50%); max-width: 100px; }
    .live-drawing-header {position: relative; z-index: 1;
        .last-draw-header { margin-bottom: 45px; display: flex; flex-wrap: wrap; align-items: center; justify-content: center;
            h6 {color: #fff; text-transform: capitalize; font-weight: 700; letter-spacing: -0.01em; font-size: 16px; line-height: 20px;
                text-transform: capitalize; margin: 0;
            }
            ul { padding: 0; margin-left: 20px; margin-bottom: 0;
                li { margin: 0px 5px; background: radial-gradient(41.07% 41.07% at 44.64% 41.07%, rgba(71, 102, 255, 0.465) 0%, rgba(73, 185, 255, 0.465) 23.96%, rgba(63, 107, 255, 0.863116) 77.08%, rgba(31, 68, 255, 0.93) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #73A6FE; width: 24px; height: 24px; border-radius: 50%; color: #fff;letter-spacing: -0.04em;font-size: 12px;line-height: 22px; display: inline-flex; align-items: center; justify-content: center; }
            }
        }
        h1 { color: #2560E9;letter-spacing: 0.02em; font-weight: 700; text-transform: uppercase; margin-bottom: 30px;}
     }
     .live-drawing-list { padding: 0; display: flex; flex-wrap: wrap; justify-content: center; list-style-type: none; margin-bottom: 150px;position: relative; z-index: 1;
        li { position: relative; margin: 0px 10px;
            span {   position: relative; z-index: 1;
                width: 64px !important; height: 64px;
                border-radius: 4px;
                display: flex !important; align-items: center !important; justify-content: center; align-items: center; border-radius: 7.11111px; background: url('../../images/polygon-img.png')no-repeat center center/100% 100%; }
            // &::after { position: absolute; top: -5px; left: -5px; width: calc(100% + 10px); height: calc(100% + 10px); content: ''; background: #355eba; border-radius: 4px;  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
            //     clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%); z-index: -1; }
            h3 { margin: 0 !important;   font-weight: 700;color: #3F5DFF; background: transparent;-webkit-background-clip: unset; -webkit-text-fill-color : unset; }
        }
     }
     .live-drawing-footer { font-weight: 700;position: relative; z-index: 1;
        font-size: 18px;
        line-height: 24px;letter-spacing: -0.01em;
        text-transform: capitalize; margin-bottom: 25px; }
     .next-draw-footer { padding: 14px 32px; display: inline-block;background: rgba(255, 255, 255, 0.2); text-align: center; position: relative; z-index: 1;
        box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25); border: 1px solid #355eba; position: relative; border-radius: 20px;
        p {font-size: 16px; line-height: 26px; position: relative; z-index: 1; margin-bottom: 15px;  }
        ul { display: flex; flex-wrap: wrap; list-style-type: none; padding: 0; margin: 0;  justify-content: center; position: relative; z-index: 1;
            li {  position: relative; margin-right: 40px;
                &::after { content: ':'; font-size: 32px; line-height: 39px; position: absolute; top: -3px; right: -24px; font-weight: 700;  }
                &:last-of-type:after { display: none; }
                &:last-child { margin-right: 0; }
            }
        }
        .starimg { position: absolute;top: 25px; right: -17px; z-index: 1; }
        &::after { position: absolute; top: 0;  left: 0; width: 100%; height: 100%;content: ''; background: rgba(11, 10, 10, 0.7); border-radius: 20px; }
     }
     .live-drawing-inner-sec { position: relative; max-width: 90%; margin: 0px auto; margin-bottom: 100px; background: rgba(0, 0, 0, 0.5); border-radius: 15px; border: 2px solid rgb(73, 185, 255); padding: 34px 0px;
        .live-dwaing-bg { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); max-width: 100%; opacity: 0.45;  width: 100%; max-width: 100%; }
        &::after { position: absolute; top: 0; left: 0;width: 100%; height: 100%; content: ''; background: rgba(35, 34, 34, 0.35); border-radius: 20px; }
        .redballimg { position: absolute; top: 98%; right: 20%; position: absolute; z-index: -1; max-width: 80px; }
        .diceimg { position: absolute; top: 80%; left: -35px; position: absolute; z-index: 1; max-width: 60px; }
     }
}
@media(min-width:768px) and (max-width:991px){
    .live-drawing-sec { padding-top: 80px; }
    .live-drawing-sec .next-draw-footer ul li::after { top: -6px; }
}
@media(max-width:767px){
    .live-drawing-sec { padding-left: 0;
        .live-drawing-header{
            .last-draw-header{
                h6 { width: 100%; margin-bottom: 10px; }
                ul { margin-left: 0; }
            }
        }
        .live-drawing-list {
            li { margin: 10px;
                h3 { font-size: 18px;
                    span { width: 30px !important; height: 30px; }
                }
            }
        }
        .live-drawing-footer {
            p { font-size: 16px; max-width: 90%; margin-left: auto; margin-right: auto; }
        }
        .next-draw-footer {
            .starimg { right: -16px; }
            ul {
                li{ margin-right: 20px;
                    &::after { right: -18px; top: -10px; }
                    h3 { font-size: 20px; min-width: 32px; text-align: center; }
                    span { font-size: 14px; }
                }
            }
        }
    }
}