.winning-sec {  color: #fff; padding-left: 80px;position: relative; overflow: hidden;
    .blueballimg { position: absolute; right: -20px; top: 50%; transform: translateY(-50%); max-width: 100px; }
    h1 {color: #2560E9;letter-spacing: 0.02em; font-weight: 700; text-transform: uppercase; margin-bottom: 30px;}
    .winning-inner-sec { position: relative; max-width: 90%; margin: 0px auto; margin-bottom: 100px; border-radius: 15px;  padding: 34px 0px;

    }
    .winning-header { background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%); margin-bottom: 20px; position: relative; z-index: 99;
        backdrop-filter: blur(12px);
        border-radius: 20px; display: flex; flex-wrap: wrap; padding: 20px 25px;
        .winning-header-left { width: 70%;display: flex; flex-wrap: wrap; position: relative; align-items: center;
            h3 { font-size: 24px; margin-bottom: 0;
                line-height: 28px; font-weight: 700; }
            ul { list-style-type: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap;
                li {   position: relative; z-index: 1;margin: 0px 3px;
                    width: 36px !important; height: 36px;
                    border-radius: 4px; color: #000A3D; font-weight: 700;
                    display: flex !important; align-items: center !important; justify-content: center; align-items: center; border-radius: 7.11111px; background: url('../../images/polygon-white.svg')no-repeat center center/100% 100%; }
            }

        }
        .winning-header-right { text-align: right; width: 30%;font-weight: 500;

            span { margin-right: 12px; display: inline-block;  font-size: 14px;
                line-height: 24px; font-weight: 500; }
                .winner-select-sec { position: relative; display: inline-block; background: rgba(255, 255, 255, 0.12);
                    border: 1px solid rgba(20, 121, 184, 0.8);
                    border-radius: 8px; color: #fff;   text-align: right;
                    &::after { position: absolute; top: 10px; left: 12px; width: 22px; height: 22px; background: url('../../images/calendar-blue.svg')no-repeat center center/cover; content: ''; z-index: 0; }
                    &::before { position: absolute; top: 50%; transform: translateY(-50%); right: 10px; background: url('../../images/down-angle.svg')no-repeat center center/cover; width: 10px; height: 5px; content: '';  }
                }
            select
                { text-align: center; -webkit-appearance: none;
                    /*webkit browsers */
                    -moz-appearance: none;
                    /*Firefox */
                    appearance: none;padding: 10px 20px;width: 160px; background: rgba(0, 0, 0, 0.4);  border-radius: 8px; opacity: 1;color: #fff; padding-left: 40px; border: none; outline: none; z-index: 1;position: relative;
            }
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
            background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
        }
        .react-datepicker-wrapper { display: inline-block; width: auto;
            input { background: rgba(255, 255, 255, 0.12);
                border: 1px solid rgba(20, 121, 184, 0.8);
                border-radius: 8px; color: #fff; padding: 10px 20px; width: 160px; text-align: right; }
        }
        .react-datepicker__input-container { position: relative; cursor: pointer; outline: none;
            input { outline: none; cursor: pointer; }
            &::after { position: absolute; top: 12px; left: 18px; width: 22px; height: 22px; background: url('../../images/calendar-blue.svg')no-repeat center center/cover; content: ''; }
        }
        .react-datepicker__header { padding-top: 20px; }
        .react-datepicker__navigation { top: 20px; }
        .react-datepicker__month { padding: 10px; }
        .react-datepicker__month-container { border-radius: 24px;}
        .react-datepicker { border-radius: 24px;}
        .react-datepicker-popper { z-index: 9999;}
        .react-datepicker__header {border-top-right-radius:24px; border-top-left-radius:24px; background: transparent;  }
        .winning-header-right { width: 30%; }
     }
     .winning-number-main-sec { position: relative;
        .redballimg { position: absolute; top: 98%; right: 20%; position: absolute; z-index: -1; max-width: 100px; }
     }
     .winning-number-sec { background: rgba(0, 0, 0, 0.4);
        box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(12px); padding: 32px 50px;border: 2px solid rgb(73, 185, 255);
        border-radius: 20px; display: flex; align-items: center; margin-bottom: 30px;backdrop-filter: blur(12px);
        .rdt_TableHeadRow .rdt_TableCol div { overflow: visible; padding: 0px 15px; }
        .winning-number-left-sec{ position: relative;
            max-width: 235px; margin-right: 35px;
            img { max-width: 100%; }
            .winning-info-sec { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); text-align: center;
                h3 {font-weight: 700;
                    font-size: 22px;
                    line-height: 32px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    color: #FFFFFF; margin-bottom: 30px; position: relative;
                    &::after {
                        position: absolute;
                        top: 120%;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(
                          90deg,
                          rgba(73, 185, 255, 0) 0%,
                          #49b9ff 29.17%,
                          #3f5dff 71.35%,
                          rgba(63, 93, 255, 0) 100%
                        );
                        border-radius: 8px;
                        content: "";
                        transition: 0.3s;
                      }
                }
                p { font-weight: 500;
                    font-size: 14px;
                    line-height: 16px; margin-bottom: 10px; }
            }
        }
        .winning-number-right-sec{ max-width: calc(100% - 270px); width: 100%;
            .total-winning-numbers {  color: #628def; text-decoration: none;font-size: 18px; font-weight: 700; cursor: pointer; }
        }
    }
}
.common-table-sec {
    .winning-number { display: flex; flex-wrap: wrap;
        li.green { margin: 0px 6px; background: #54FC01; padding-top: 2px; width: 26px; height: 26px; border-radius: 50%; color:#000A3D;letter-spacing: -0.04em;font-size: 12px;line-height: 24px; display: inline-block; transform: none; }
        li {
            margin: 0px 6px; background: radial-gradient(41.07% 41.07% at 44.64% 41.07%, rgba(71, 102, 255, 0.465) 0%, rgba(73, 185, 255, 0.465) 23.96%, rgba(63, 107, 255, 0.863116) 77.08%, rgba(31, 68, 255, 0.93) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #73A6FE;  width: 26px; height: 26px; border-radius: 50%; color: #fff;letter-spacing: -0.04em;font-size: 12px;line-height: 24px; display: inline-flex; align-items: center; justify-content: center; transform: none; padding: 0;
        }
    }
    .rdt_Pagination{
        svg { fill:#417cff}
        svg { transform: scale(1); transition: 0.4s;
            &:hover { transform: scale(1.2);transition: 0.4s; }
          }
      }
    .rdt_TableHead { margin-bottom: 10px; }
}
.winner-popup { max-width: 90% !important; z-index: 9999;
    .modal-content {padding: 0; max-height: 75vh; overflow-y: auto;
        .common-close-btn { z-index: 999; }
    }
}
.winning-details-sec {
    position: relative;

    margin: 0px auto;
    padding: 34px 32px; background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.25);

    &::after { position: absolute; bottom: 1px; left: 1px; width: 99%; height: 99%; content: '';backdrop-filter: blur(12px); border-radius: 25px;}
    div { position: relative; z-index: 12; }
    .nori-details-top-sec { display: flex; flex-wrap: wrap;  align-items: center; margin-bottom: 30px;
        a { color: #fff; text-decoration: none; font-size: 16px; cursor: pointer;
            line-height: 24px;
            img { margin-right: 10px; }
        }
        p {font-size: 16px;
            line-height: 19px; margin-bottom: 0; }
    }
    .nori-details-header{ z-index: 97;background: #1F1F1F; margin-bottom: 32px; text-align: left;
        border-radius: 8px; padding: 16px 24px;
        label { font-weight: 500;
            font-size: 14px;
            line-height: 20px; margin-bottom: 6px; }
        h3 { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; }
    }
    .common-title { margin-bottom: 30px; flex-wrap: wrap; justify-content: center;
        span { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; }
    }

    .nori-details-main-sec { text-align: left;
        ul { list-style-type: none; padding: 0; margin: 0;  overflow-y: auto;
            li { font-weight: 600;
                font-size: 18px;
                line-height: 24px; margin-bottom: 20px;
                ul { padding-left: 45px; margin-top: 18px;  list-style-type: none; max-width: 80%;
                    li { margin-bottom: 0; font-weight: 400; margin-bottom: 10px; font-size: 16px; position: relative;
                        line-height: 24px; display: flex; align-items: center; justify-content: space-between;
                        span { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent; }
                        &::after { position: absolute; top: 7px; left: -20px; width: 8px; height: 8px; background: #fff; border-radius: 50%; content: ''; }
                    }
                 }
                 ul.nori-green-list { margin-top: 0; display: inline-flex; padding: 0; margin-left: 20px;
                    li { width: 24px; height: 24px; background: radial-gradient(50% 50% at 50% 50%, #DBF0D1 0%, #54FC01 100%); border-radius: 50%; color: #000A3D;
                        display: flex; align-items: center; justify-content: center;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);font-weight: 500;
                        font-size: 14px;
                        line-height: 22px; margin-right: 12px; margin-bottom: 0;
                        &::after { display: none; }
                    }
                }
                ul.nori-red-list { margin-top: 0; display: inline-flex; flex-wrap: wrap; padding: 0; margin-left: 20px;
                    li { width: 24px; height: 24px; background: radial-gradient(50% 50% at 50% 50%, #FE8EA0 0%, #FC002A 100%); border-radius: 50%; color: #000A3D;
                        display: flex; align-items: center; justify-content: center;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);font-weight: 500;
                        font-size: 14px;
                        line-height: 22px; margin-right: 12px; margin-bottom: 0;
                        &::after { display: none; }
                    }
                }
                ul.nori-yellow-list { margin-top: 0; display: inline-flex; padding: 0;
                    li { width: 24px; height: 24px; background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FCA801 100%); border-radius: 50%; color: #000A3D;
                        display: flex; align-items: center; justify-content: center;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);font-weight: 500;
                        font-size: 14px;
                        line-height: 22px; margin-right: 12px; margin-bottom: 0;
                        &::after { display: none; }
                    }
                }
                ul.nori-normal-list { margin-top: 0; display: flex; flex-wrap: wrap; padding: 0; margin-left: 0px; width: 100%; max-width: 100%;
                    label { width: 100%; margin: 5px 0px; }
                    ul { padding: 0;  margin-top: 0; display: inline-flex; flex-wrap: wrap; padding: 0;
                        li {  width: 24px; height: 24px; background: radial-gradient(50.07% 41.07% at 44.64% 41.07%,hsla(0,0%,100%,.342) 0,hsla(0,10%,85%,.123) 56.96%,rgba(0,0,0,.437) 100%),#fff !important; border-radius: 50%; color: #000A3D;
                            display: flex; align-items: center; justify-content: center;
                            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);font-weight: 500;
                            font-size: 14px;
                            line-height: 22px; margin-right: 12px; margin-bottom: 10px;
                            &::after { display: none; }
                        }
                    }
                }
                ul.nori-partition-list {  list-style-type: none;
                    li { margin-left: 10px; margin-right: 10px; width: 24px; height: 24px; background: radial-gradient(50.07% 41.07% at 44.64% 41.07%,hsla(0,0%,100%,.342) 0,hsla(0,10%,85%,.123) 56.96%,rgba(0,0,0,.437) 100%),#fff !important; border-radius: 50%; color: #000A3D;
                        display: flex; align-items: center; justify-content: center;
                        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        &::after { display: none;  }
                    }
                 }
                 div { padding-left: 45px; margin-top: 8px; width: 100%; display: flex; flex-wrap: wrap; position: relative;
                    span { margin-right: 10px; display: inline-block; background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;margin-bottom: 10px; }
                        &::after { position: absolute; top: 7px; left: 23px; width: 8px; height: 8px; background: #fff; border-radius: 50%; content: ''; }
                }
            }
        }
     }
}
.nora-642-popup .modal-content { padding-bottom: 0 !important; }
.previeew-main-sec {margin-top: 40px; }
.compitition-winner-sec .common-white-btn  { margin-bottom: 10px !important; padding: 10px 16px; }
@media(min-width:992px) and (max-width:1199px){
    .winning-sec{
        .winning-header {
            .winning-header-left { width: 100%; text-align: center; justify-content: center; }
            .winning-header-right { width: 100%; text-align: center; margin-top: 15px; }
         }
    }
    .winning-details-popup::after   { display: none; }
}
@media(min-width:768px) and (max-width:991px){
    .winning-sec{
        .winning-inner-sec { max-width: 95%; }
        .winning-header {
            .winning-header-left { width: 100%; text-align: center; justify-content: center; }
            .winning-header-right { width: 100%; text-align: center; margin-top: 15px; }
         }
    }
    .winning-details-sec { max-width: 100%;
        .nori-details-main-sec ul li ul { max-width: 100%;  }
    }
    .stacking-main-sec .referral-tab-sec .refral-right-sec { max-width: 250px; margin: 0px auto; margin-top: 20px; }
    .winning-details-popup::after   { display: none; }
}
@media(max-width:767px){
    .winning-sec{ padding-left: 0;
        .winning-inner-sec { max-width: 90%; }
        .winning-header {
            .winning-header-left { width: 100%; text-align: center; justify-content: center; }
            .winning-header-right { width: 100%; text-align: center; margin-top: 15px; }
         }
         .winning-number-sec { flex-wrap: wrap; flex-direction: column; padding: 20px 25px; align-items: center;
            .winning-number-left-sec { margin-right: 0; margin-bottom: 20px; }
            .winning-number-right-sec { width: 100%; max-width: unset;
                .rdt_TableCol { padding: 0px 15px !important; }
            }
          }
    }
    .winning-details-sec { max-width: 100%;
    .nori-details-main-sec ul li ul { max-width: 100%; flex-wrap: wrap;
            li { flex-wrap: wrap; margin-bottom: 5px !important;
                span { width: 100%; }
            }
        }
    }
    .nori-details-main-sec ul li ul.nori-red-list { margin-left: 0 !important; margin-top: 5px !important; flex-wrap: wrap;
        li { margin-bottom: 10px !important; }
    }
    .result-popup .common-winning-sec .winning-noris-list li span { display: block; width: 100%; margin-bottom: 10px; }
    .winning-details-sec .nori-details-main-sec ul li div { padding-left: 50px;
        &::after { left: 25px; }
    }
    .common-table-sec .winning-number li.green { margin-bottom: 10px; }
    .winning-sec .winning-header .winning-header-left ul { margin-top: 10px; }
    .winning-details-popup::after   { display: none; }
}