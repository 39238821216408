.delete-ticket-btn { margin-right: 15px; }
.my-game-sec { padding-bottom: 50px; 
min-width: 345px;}
.result-popup {
    .result-popup-header { display: flex; flex-wrap: wrap; justify-content: center; margin-top: 10px; margin-bottom: 25px; align-items: center;
        h5 { font-weight: 400;
            font-size: 16px;
            line-height: 28px; margin-bottom: 0;
            strong { font-weight: 700;color: #417cff;  }
        }
        .result-popup-header-data { margin-right: 75px;
            &:last-child { margin-right: 0; background: #1F1F1F;
                border-radius: 6px; padding: 4px 16px; }
        }
    }
    .common-winning-sec { margin-top: 10px; max-width: 350px; margin-left: auto; margin-right: auto;
        h5 { position: relative; margin-bottom: 30px;
            &::after { width: 100%; height: 1px; background: rgba(20, 121, 184, 0.8); position: absolute; bottom: -12px; left: 0; content: ''; }
        }
        .winning-list { margin-top: 40px; padding: 0; list-style-type: none; text-align: center; display: flex; flex-wrap: wrap; justify-content: center;
            > li { margin-bottom: 12px; width: 100%; text-align: center;
                label { width: 30px !important; height: 30px; background: url('../../images/polygon-img.svg')no-repeat center center/100% 100%;
                    border-radius: 4px; display: flex; align-items: center; justify-content: center; font-size: 14px;; }
            }
            ul {padding: 0; list-style-type: none; display: flex; flex-wrap: wrap; justify-content: center;
                > li { width: 40px !important; height: 40px; margin: 0px 4px;
                    border-radius: 4px;
                    display: flex !important; align-items: center !important; justify-content: center; align-items: center; border-radius: 7.11111px; font-weight: 700;color: #000A3D;font-weight: 600;
                    font-size: 20px;
                    line-height: 22px;
                }
                .green {
                    background: url('../../images/polygon-green.svg')no-repeat center center/100% 100%;
                }
                .white {
                    background: url('../../images/polygon-white.svg')no-repeat center center/100% 100%;
                }
            }
         }
         .winning-list.nori-score-list {
            ul {
                > li { position: relative;
                    span { position: absolute; bottom: 100%; left: 1px; color: #fff; font-size: 10px; font-weight: 500; }
                }
            }
         }
         .winning-noris-list { list-style-type: none; padding: 0;
            li { margin-bottom: 15px; font-size: 16px;
                line-height: 24px;
                &:last-child { margin-bottom: 0; }
            }
         }
     }
     .ticket-details-header { margin-top: 15px;
        .header-detail-item { margin-bottom: 10px; text-align: center; max-width: 350px; margin-left: auto; margin-right: auto;
            ul { padding: 0; margin-bottom: 10px; display: flex; flex-wrap: wrap; list-style-type: none;
             li.header-detail-item-left { width: 60%; text-align: left;
                font-size: 16px;line-height: 28px;
                strong {  color: #2560E9; }
            }
             li.header-detail-item-right { width: 40%; text-align: left;
                 .form-grp {  display: inline-flex; align-items: center; position: relative; padding-left: 30px;
                     label { font-weight: 400; margin-top: 2px;
                         font-size: 16px;
                         line-height: 22px;
                         text-transform: capitalize;
                         color: #FFFFFF; margin-left: 5px;

                         &::before {
                             content:'';
                             -webkit-appearance: none;
                             border: 2px solid rgba(73, 185, 255, 1)
                             ;
                             backdrop-filter: blur(24px);
                             border-radius: 4px;

                             padding: 9px;
                             display: inline-block;
                             position: absolute;
                             left: 0;
                             vertical-align: middle;
                             cursor: pointer;
                             margin-right: 5px;
                         }
                     }
                     .tooltiptext { right: unset; left: 15px; }
                 }
                 input:checked + label:after {
                     content: '';
                     display: block;
                     position: absolute;
                     top: 7px;
                     left: 5px;
                     width: 13px;
                     height: 10px;
                     background: url('../../images/tik-icon.png')no-repeat center center/cover;
                   }
                   input:checked + label::before {
                     background: #fff;
                   }
                 .info-icon { margin-left: 16px; }
                 input {background: #FFFFFF;
                     position: absolute;
                     top: 0;
                     left: 0;
                     width: 100%; height: 100%;
                     opacity: 0;
                     z-index: 1;
                     cursor: pointer;
                  }
             }
         }
          }
     }
     .ticket-details-list { margin-top: 30px; margin-bottom: 30px;
        > ul { padding: 0; margin: 0; list-style-type: none; max-height: 300px; overflow-y: auto;
            li { margin-bottom: 20px; display: flex; flex-wrap: wrap; justify-content: center;
                ul { display: flex; flex-wrap: wrap;list-style-type: none;padding: 0; margin: 0; margin-left: 20px;
                    li  { font-size: 13px; line-height: 24px; width: 24px; height: 24px;background: radial-gradient(41.07% 41.07% at 44.64% 41.07%, rgba(71, 102, 255, 0.465) 0%, rgba(73, 185, 255, 0.465) 23.96%, rgba(63, 107, 255, 0.863116) 77.08%, rgba(31, 68, 255, 0.93) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #73A6FE;
                        border-radius: 50%; margin: 0px 5px;text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); display: flex; justify-content: center; align-items: center; }
                }
            }
        }
      }

}
.my-games-main-sec{
    h1 { color: #4C9CF2; margin-top: 15px; margin-bottom: 30px; text-transform: uppercase;font-weight: 700; }
}
.view-nori-btn { margin-top: 20px !important; font-weight: 500; }
.my-games-table a { cursor: pointer; }
.statics-main-sec-holder{
    width: 100%;
    height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
    >.fleche{
        position: absolute;
        right: 200px;
        top: 0;
        height: 50%;
        z-index: 2;
        
    }
}
.notConnectedBG { 
    position: relative; 
    display: inline-block; 
    >img{
        position: absolute;
        &.blue{
            z-index: 3;
            width: 50%;
            top: -100px;left: -70px;
        }
        &.red{
            z-index: 1;
            width: 100%;
            right: 0;
            top: -175px;
            left: 25%;
            filter: blur(4px);
        }
        &.green{
            width: 40%;
            top: 40px;
            left: 5%;
            z-index: 5;
        }
    }
    >div{
        position: relative;
        z-index: 4;
        height: 3px;
        h3{
            background-color: black;
            border-radius: 50px;
            padding: 12px;
            text-align: center;
        }
    }
 }

@media (max-width:767px){
    .result-popup {
        .result-popup-header { width: 100%;
            .result-popup-header-data { margin-right: 0; margin-bottom: 10px; width: 100%;
                &:last-child { margin-bottom: 0; }
            }
        }
        .modal-content { padding-top: 60px; max-width: 95%; margin-left: auto; margin-right: auto; max-height: 70vh; overflow-y: auto; }
        .common-winning-sec { max-width: 100%;
            .winning-list {
                ul {
                    > li {width: 30px !important; height: 30px; font-size: 16px;

                    }
                }
            }
        }
        .ticket-details-header{
            .header-detail-item{
                ul{
                    li.header-detail-item-left { width: 50%;}
                    li.header-detail-item-right { width: 50%; }
                }
            }
        }
        .ticket-details-list {
            > ul {
                li {
                    ul { margin-left: 0; margin-top: 5px; width: 100%; justify-content: center; }
                }
            }
         }
    }
    .common-table-sec {
        .rdt_TableCell { font-size: 14px;}
    }
    .statics-main-sec-holder>.fleche{
        right: 0px;
    }
    .my-games-main-sec h1 { margin: 15px 0px; }
    .my-game-sec { min-height: 70vh; margin-bottom: 20px; }
    .result-popup .common-winning-sec .winning-list { margin-top: 60px; }
    .result-popup .common-winning-sec .winning-list.result-winning-list { margin-top: 0; }
    .winning-details-sec .nori-details-header p { margin-bottom: 5px; }
    .mob-delet-btn      { margin: 0 !important;}

}