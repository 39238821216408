.competition-sec { text-align: center; max-width: 850px; margin: 40px auto;
    h1 { color: #2560E9;letter-spacing: 0.02em; font-weight: 700; text-transform: uppercase; margin-bottom: 30px;
    }
    p { font-size: 22px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
        a { background: linear-gradient(70.0deg, #FFCA61 0%, #FCA801 100%); cursor: pointer;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; text-decoration: underline; font-weight: 700; position: relative;
            &::after { position: absolute; top: 100%; left: 0; width: 100%; height: 2px; background: linear-gradient(70.0deg, #FFCA61 0%, #FCA801 100%); content: ''; }
        }
    }
    .compititaion-inner-sec  { position: relative; display: inline-block; margin-top: 40px;
        > img { max-width: 720px; }
        > div { position: absolute; top: 53%; left: 45%; transform: translate(-40%,-40%);
            h3 { max-width: 80%; margin: 0px auto; text-transform: uppercase;color: #000A3D; font-weight: 700; margin-bottom: 16px; margin-bottom: 15px; }
            a {text-transform: uppercase;font-weight: 700 !important; font-size: 20px; }
        }
        .common-blue-btn.small-btn { min-width: 220px; }
     }
}
@media (min-width:768px) and (max-width:991px){
    .competition-sec {
        .compititaion-inner-sec {
            img { max-width: 100%;}
        }
    }
}
@media (max-width:767px){
    .competition-main-sec { min-height: unset; }
    .competition-sec { padding-left: 0;
        .compititaion-inner-sec { overflow: hidden;
            > div { left: 50%; }
            h3 { max-width: 100% !important; font-size: 15px; margin-bottom: 8px !important; }
            img { max-width: 100%;  transform-origin: center; }
            a { font-size: 12px !important; padding: 10px 12px;}
            .common-blue-btn.small-btn { min-width: 150px; }
        }
    }
}