.error-msg {
    color: red;
    font-size: 12px;
    margin-top: -5px;
    display: none;
}

.generation-completed {
    text-align: center;
    margin-top: 20px;

    span {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        bottom: 15%;
        position: relative;
    }

    img {
        animation-name: swipeRight;
        position: absolute;
        right: -35px;
        animation-duration: 1.5s;
        animation-iteration-count: 2;
    }
}

.error-msg.show {
    display: block;
}

.mandetory-number-list.drag-item-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;

    >section {}
}

.activeProgressbar {
    position: relative;
    z-index: 99;
    text-align: center;

    img {
        max-width: 100px;
    }
}

@keyframes swipeRight {
    0% {
        right: -35px;
    }

    50% {
        right: -55px;
    }

    100% {
        right: -35px;
    }
}

@keyframes swipeBottom {
    0% {
        top: 25px
    }

    50% {
        top: 45px
    }

    100% {
        top: 25px
    }
}

.info-popup-btn {
    position: relative;

    a.common-white-btn {
        padding-right: 30px;
    }

    a.tooltip-heading {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        margin: 0;

        &:hover .tooltiptext {
            display: none;
        }
    }
}

.common-blue-btn.small-btn.disabled,
.common-white-btn.small-btn.disabled {
    background: radial-gradient(50% 50% at 50% 50%, #777777 0%, #8989A4 100%) !important;
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 1;
        cursor: not-allowed;
    }

    span {
        color: #fff !important;
    }

    &:hover {
        color: #fff;

        span {
            color: #fff !important;
        }
    }
}

.common-table-sec.use-nori-table {
    .rdt_TableHeadRow {
        div {
            overflow: visible
        }
    }

    .rdt_TableCol_Sortable {
        span {
            z-index: 999;
        }
    }
}

.stepper-sec {
    padding: 40px 0px;
    padding-left: 75px;
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 125px);
    min-width: 350px;

    .blueballimg {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100px;
    }

    .sidebar {
        position: absolute;
        top: 16px;
        left: 0;
        background: rgba(38, 38, 38, 0.8);
        border-radius: 0px 36px 36px 0px;
        padding: 0;
        width: 75px;

        li {
            &:first-child {
                a {
                    border-radius: 0px 36px 0px 0px;
                    cursor: default;
                }
            }

            &:first-child:hover {
                a {
                    background: rgba(38, 38, 38, 0.8);
                }
            }

            &:last-child {
                a {
                    border-radius: 0px 0px 36px 0px;
                    font-size: 8.5px;
                }
            }

            a {
                width: 100%;
                height: 92px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                letter-spacing: -0.01em;

                color: #FFFFFF;
                font-size: 10px;
                line-height: 18px;
                font-weight: 600;
                text-decoration: none;
            }

            &:hover {
                a {
                    background: radial-gradient(64.38% 64.38% at 35.13% 21.75%, rgba(73, 185, 255, 0.5) 0%, rgba(61, 90, 255, 0.1) 100%);
                }
            }

            .active {
                background: radial-gradient(64.38% 64.38% at 35.13% 21.75%, rgba(73, 185, 255, 0.5) 0%, rgba(61, 90, 255, 0.1) 100%);
            }
        }
    }

    h1 {
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        text-align: center;
        background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 12px;
        padding-left: 75px;
        position: relative;
    }

    .common-step-box {
        color: #fff;
        position: relative;
        z-index: 99;

        .nori-mandetory-ticket-sec {
            display: inline-block;

            .nori-ticket-sec {
                position: relative;
                display: inline-block;
                margin: 0px 30px;
                position: relative;
                max-width: 280px;

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: 55px;
                    left: 30px;
                    margin: 0px -2px;

                    li {
                        cursor: pointer;
                        display: inline-block;
                        width: 22px;
                        line-height: 22px;
                        background: radial-gradient(50.07% 41.07% at 44.64% 41.07%, rgba(255, 255, 255, 0.342) 0%, rgba(220, 212, 212, 0.122906) 56.96%, rgba(0, 0, 0, 0.437) 100%), #FFFFFF;
                        color: rgba(0, 10, 61, 1);
                        border-radius: 50%;
                        position: relative;
                        font-size: 12px;
                        line-height: 22px;
                        margin: 0px 2px;
                        font-weight: 500;
                        border: 1px solid rgb(73, 185, 255);
                        margin-top: 1.5px;

                        &:nth-of-type(5)+br {
                            width: 100%;
                            display: block;

                            &::before {}
                        }

                        &:nth-of-type(11)+br {
                            display: block;
                        }

                        &:nth-of-type(18)+br {
                            display: block;
                        }

                        &:nth-of-type(26)+br {
                            display: block;
                        }

                        &:nth-of-type(33)+br {
                            display: block;
                        }

                        &:nth-of-type(39)+br {
                            display: block;
                        }

                        a {
                            text-decoration: none;
                            color: #000A3D;
                        }
                    }

                    br {
                        display: block;
                        width: 100%;
                        display: none;
                    }

                    li.active {
                        background: radial-gradient(50% 50% at 50% 50%, #DBF0D1 0%, #54FC01 100%);
                    }

                    li.red {
                        background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FC002A 100%);
                    }

                    li.yellow {
                        background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FCA801 100%);
                    }

                    li.disabled {
                        background: radial-gradient(50% 50% at 50% 50%, #F4F4F5 0%, #B9B9C6 100%);
                        opacity: 0.8;
                        color: rgba(0, 10, 61, 0.6);
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        order: 1;
                    }

                    &::before {
                        content: '';
                        width: 100%;
                        order: 1;
                    }
                }

                &::after {
                    position: absolute;
                    top: 10%;
                    width: 110px;
                    height: 22px;
                    background: url('../../images/connection-right.png')no-repeat center center/auto 100%;
                    content: '';
                    right: 89%;
                }

                &::before {
                    position: absolute;
                    bottom: 15%;
                    width: 100px;
                    height: 34px;
                    background: url('../../images/connection-left.png')no-repeat center center/auto 100%;
                    content: '';
                    right: 90%;
                }

                &:first-of-type:after,
                &:first-of-type::before {
                    display: none;
                }

                img {
                    max-width: 100%;
                }
            }

            .ticketslidersec {
                margin-bottom: 60px;

                .slick-slide.slick-active {
                    position: relative;
                    z-index: 99;
                }
            }

            .slick-arrow.slick-prev {
                bottom: 50%;
                transform: translateY(-50%);
                left: calc(50% - 180px);
                top: unset;
                background: url('../../images/arrow-left.png')no-repeat center center/100% auto;
                z-index: 99;

                &::before {
                    content: '';
                }
            }

            .slick-disabled {
                opacity: 0.5;
            }

            .slick-arrow.slick-next {
                bottom: 50%;
                transform: translateY(-50%);
                right: calc(50% - 180px);
                top: unset;
                background: url('../../images/arrow-right.png')no-repeat center center/100% auto;
                z-index: 99;

                &::before {
                    content: '';
                }
            }

            .slick-dots {
                li {
                    width: 8px;
                    height: 8px;
                    margin: 0px 5px;

                    button {
                        width: 8px;
                        height: 8px;
                        background: #fff;
                        border-radius: 50%;

                        &::after {
                            content: '';
                        }

                        &::before {
                            display: none;
                        }
                    }
                }

                li.slick-active {
                    button {
                        background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    }
                }
            }

            .mandetory-number-footer {
                display: block;
                margin-top: 25px;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;

                >div {
                    display: inline-block;
                    padding: 2px 12px;
                    border: 2px solid rgba(73, 185, 255, 0.8);
                    border-radius: 20px;

                    span {
                        color: #2560E9;
                        margin-right: 5px;
                    }
                }

                .drop-sec {
                    position: relative;
                    border: none;
                    padding: 0;
                    cursor: pointer;

                    &::after {
                        font-size: 20px;
                        position: absolute;
                        top: 10px;
                        right: 7px;
                        width: auto;
                        height: auto;
                        content: '\f0d7';
                        font-family: "FontAwesome";
                        color: #79747E;
                    }
                }

                select {
                    padding: 8px 26px 7px 12px;
                    border: 2px solid rgba(73, 185, 255, 0.8);
                    border-radius: 8px;
                    cursor: pointer;
                    color: #fff;
                    background: #100f18;
                    margin-left: 5px;
                    // -webkit-appearance: none;
                    // -moz-appearance:    none;
                    // appearance:         none;
                    outline: none;
                    position: relative;
                    z-index: 1;

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .filter-list-sec {
            .filter-list-item {
                margin-bottom: 12px;
                display: flex;
                flex-wrap: wrap;

                .filter-left-sec {
                    width: 70%;
                    display: flex;
                    align-items: center;

                    .tooltip-heading+span {
                        display: inline-block;
                        margin-top: 2px;
                    }

                    img {
                        margin-right: 6px;
                    }

                    label {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        text-transform: capitalize;
                        color: #FFFFFF;
                    }

                    .tooltiptext {
                        right: unset;
                        left: 12px;
                        top: 22px;
                        max-height: 150px;
                        max-width: 50vw;
                        overflow-y: auto;
                    }
                }

                .filter-header.filter-right-sec {
                    justify-content: space-around;

                    label {
                        margin-bottom: 16px;
                        margin-left: 0px;
                        min-width: 100px;
                    }
                }

                .filter-right-sec {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0px -12px;

                    >div {
                        min-width: 77px;
                        margin: 0px 12px;
                        text-align: center;

                        label {
                            width: 100%;
                            text-align: center;
                        }
                    }

                    div.filter-select {
                        position: relative;

                        div {
                            border: 2px solid rgba(73, 185, 255, 0.8);
                            border-radius: 8px;
                        }

                        select {
                            padding: 10px 40px 10px 12px;
                            cursor: pointer;
                            min-width: 90px;
                            border: none;
                            border-radius: 8px;
                            background: rgba(0, 0, 0, 0.8);
                            color: #fff;
                            outline: none;
                            position: relative;
                            z-index: 1;

                            &:focus {
                                outline: none;
                            }
                        }

                        &::after {
                            opacity: 0;
                            font-size: 20px;
                            position: absolute;
                            top: 10px;
                            right: 11px;
                            width: auto;
                            height: auto;
                            content: '\f0d7';
                            font-family: "FontAwesome";
                            color: #79747E;
                            z-index: 0;
                        }
                    }

                    div.after-none.filter-select {
                        &::after {
                            display: none;
                        }
                    }

                }
            }

            hr {
                color: rgba(20, 121, 184, 0.4);
                opacity: 1;
            }
        }
    }

    .step-component {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        .steps-left-sec.left-header-sec {
            &::after {
                position: absolute;
                bottom: 0;
                left: 5%;
                width: 90%;
                height: 50%;
                content: '';
                backdrop-filter: none;
                display: none;
            }
        }

        .steps-left-sec {
            margin-bottom: 50px;
            width: 100%;
            max-width: 940px;
            min-height: 511px;
            background: rgba(0, 0, 0, 0.4);
            padding: 50px 80px 50px 80px;
            position: relative;
            border-radius: 20px;
            border: 2px solid rgb(73, 185, 255);
            position: relative;

            .nori-step-left-sec {
                position: relative;
                z-index: 2;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 5%;
                width: 90%;
                height: 50%;
                content: '';
                backdrop-filter: blur(12px);
            }

            .redballimg {
                position: absolute;
                top: 98%;
                right: 20%;
                position: absolute;
                z-index: -1;
                max-width: 100px;
            }

            .self-config-list {
                margin-top: 70px;
                z-index: 1;

                .config-form-grp {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 25px;

                    label {
                        font-weight: 400;
                        margin-top: 2px;
                        font-size: 16px;
                        line-height: 22px;
                        text-transform: capitalize;
                        color: #FFFFFF;
                        margin-left: 5px;

                        &::before {
                            content: '';
                            -webkit-appearance: none;
                            border: 2px solid rgba(73, 185, 255, 1);
                            backdrop-filter: blur(24px);
                            border-radius: 4px;

                            padding: 9px;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            vertical-align: middle;
                            cursor: pointer;
                            margin-right: 5px;
                        }
                    }

                    .tooltiptext {
                        right: unset;
                        left: 15px;
                    }
                }

                input:checked+div+label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 7px;
                    left: 5px;
                    width: 13px;
                    height: 10px;
                    background: url('../../images/tik-icon.png')no-repeat center center/cover;
                }

                input:checked+div+label::before {
                    background: #fff;
                }

                .info-icon {
                    margin-left: 16px;
                }

                input {
                    background: #FFFFFF;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 22px;
                    height: 22px;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                }

                .common-step-box {
                    position: relative;
                    z-index: 1;
                }

                >div {
                    position: relative;
                    z-index: 1;
                }
            }

            .btn-component {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 20px 30px;
                z-index: 1;

                div {
                    position: relative;

                    input {
                        background: transparent;
                        box-shadow: none;
                        color: #fff;
                        border: none;
                        padding-right: 32px;
                        cursor: pointer;
                        z-index: 1;
                        position: relative;
                    }

                    &::after {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: 2px;
                        right: 0;
                        background: url('../../images/arrow-right.png')no-repeat center center/cover;
                        content: '';
                        z-index: 0;
                    }

                    &:first-of-type {
                        input {
                            padding-right: 5px;
                            padding-left: 30px;
                        }

                        &::after {
                            right: unset;
                            left: 0;
                            background: url('../../images/arrow-left.png')no-repeat center center/cover;
                            transform: scale(1);
                            transition: 0.4s;
                        }
                    }

                    &:hover::after {
                        transform: scale(1.2);
                        transition: 0.4s;
                    }
                }
            }

            .nori-form-grp {
                padding: 16px 24px;
                background: #1F1F1F;
                border-radius: 8px;
                margin-bottom: 30px;

                label {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #FFFFFF;
                    position: relative;

                    img {
                        margin-left: 6px;
                        max-width: 12px;
                    }
                }

                .tooltiptext {
                    right: 0px;
                    max-height: 150px;
                    overflow-y: auto;
                }

                h4 {
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            .options {
                margin-bottom: 20px;
                font-size: 18px;

                >div {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    &:hover input~span {
                        background-color: #8f8f8f;
                    }

                    &:hover input:checked~span {
                        background-color: #2196F3;
                    }
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    z-index: 2;

                    &:checked~span {
                        background-color: #2196F3;
                    }

                    &:checked~span::after {
                        opacity: 100;
                    }
                }

                span {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: #eee;
                    border-radius: 5px;

                    &::before {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }

                    &::after {
                        content: "✓";
                        font-size: 30px;
                        color: white;
                        position: absolute;
                        top: -10px;
                        opacity: 0;
                        border-left-style: 10px;
                    }
                }

                label {
                    cursor: pointer;
                }
            }

            .nori-form-grp.nori-full-form {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    margin-right: 6px;
                }

                div {
                    display: flex;
                    align-items: center;
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin: 0;
                    position: relative;
                    display: flex;
                    align-items: center;

                    .tooltip-heading {
                        margin-bottom: 2px;
                    }

                    .tooltiptext {
                        right: unset;
                        left: 0;
                        top: 115%;
                        max-height: 150px;
                        overflow-y: auto;
                    }
                }

                input {
                    padding: 8px 8px 8px 12px;
                    width: 94px;
                    background: rgba(0, 0, 0, 0.8);
                    border-radius: 8px;
                    border: 1px solid rgb(73, 185, 255);
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #FFFFFF;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            h2,
            .filterRange p {
                display: flex;

                img {
                    margin: 0 10px;
                }
            }

            h2 {
                justify-content: center;
            }

            .filterRange {

                input {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    outline: none;
                    width: 100%;
                    height: 8px;
                    background-color: #417cff;
                    border-Radius: 8px;

                    &:hover {
                        cursor: pointer;
                    }

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 4px;
                        height: 16px;
                        background-image: radial-gradient(circle, #ffffff 0%, #ffffff 90%);
                        border-radius: 50px;

                        &:hover {
                            cursor: grab;
                        }
                    }
                }

                ul {
                    li {
                        list-style: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            .nori-input-form-grp {
                position: relative;
                display: inline-block;

                input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                }

                label {
                    padding-left: 35px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    position: relative;

                    &::before {
                        content: '';
                        -webkit-appearance: none;
                        border: 2px solid rgba(73, 185, 255, 1);
                        backdrop-filter: blur(24px);
                        border-radius: 4px;

                        padding: 9px;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }

                input:checked+label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 7px;
                    left: 5px;
                    width: 13px;
                    height: 10px;
                    background: url('../../images/tik-icon.png')no-repeat center center/cover;
                }

                input:checked+label::before {
                    background: #fff;
                }
            }

            .readyimg {
                margin-bottom: 20px;
                max-width: 130px;
                ;
            }

            .generate-tickets-btns {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                margin-bottom: 20px;

                a {
                    margin: 0px 8px;

                    img {
                        margin-left: 6px;
                    }
                }
            }

            .note {
                text-align: center;
                color: #00985d;
                font-size: 20px;
                font-weight: 500;
            }
        }

        @keyframes clippath {

            0%,
            100% {
                clip-path: inset(0 0 95% 0);
            }

            25% {
                clip-path: inset(0 95% 0 0);
            }

            50% {
                clip-path: inset(95% 0 0 0);
            }

            75% {
                clip-path: inset(0 0 0 95%);
            }
        }

        .step-right-slider-sec {
            position: relative;
            width: 100%;
            padding: 18px 10px;
            max-width: 170px;
            margin-left: 24px;
            background: rgba(0, 0, 0, 0.4);
            min-height: 511px;
            border-radius: 20px;
            border: 2px solid rgb(73, 185, 255);

            .generationCompleteLight {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border: 4px dashed rgb(18, 247, 37);
                    border-radius: 15px;
                    transition: all .5s;
                    animation-name: clippath;
                    animation-duration: 1.5s;
                    animation-iteration-count: 2;
                    animation-timing-function: linear;
                }
            }

            .right-step-slider {
                margin-top: 5px;
            }

            .hexagone-list {
                list-style-type: none;
                max-width: 145px;
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                border: 2px solid rgba(73, 185, 255, 0.8);
                border-radius: 8px;
                background: rgba(0, 0, 0, 0.8);
                padding: 10px;
                margin: 0px auto;
                cursor: pointer;
                margin-bottom: 5px;

                li {
                    width: 16px;
                    height: 16px;
                    margin-top: -1px;
                    -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 8px;
                    line-height: 8px;
                    background: #D2D2D2;

                    &:nth-child(7n +1) {
                        margin-left: 7px;
                        clear: both;
                    }

                    &:nth-child(14n +1) {
                        margin-left: 0px;
                    }
                }

                li.active {
                    background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    color: #fff;
                }

                .hexagone-list.orange {
                    &::after {
                        background: rgba(252, 157, 1, 0.2);
                    }
                }
            }

            .slick-arrow.slick-disabled {
                opacity: 0.3;
            }

            .slick-arrow.slick-prev {
                top: -20px;
                transform: translateX(-45%);
                transform: rotate(90deg);
                left: 45%;

                // background: url('../../images/arrow-left.png')no-repeat center center/100% auto;
                &::before {
                    content: '';
                }
            }

            .slick-arrow.slick-next {
                bottom: -15px;
                transform: translateX(-45%);
                transform: rotate(90deg);
                left: 45%;
                top: unset;

                // background: url('../../images/arrow-right.png')no-repeat center center/100% auto;
                &::before {
                    content: '';
                }
            }

            .custom-left-btn {
                top: 0px;
                transform: translateX(-45%);
                transform: rotate(90deg);
                left: 45%;
                position: absolute;
                padding: 0;
                background: transparent;
                border: none;

                img {
                    transform: scale(1);
                    transition: 0.4s;
                }

                &:hover img {
                    transform: scale(1.2);
                    transition: 0.4s;
                }
            }

            .custom-right-btn {
                bottom: 0px;
                transform: translateX(-45%);
                transform: rotate(90deg);
                left: 45%;
                top: unset;
                position: absolute;
                padding: 0;
                background: transparent;
                border: none;

                img {
                    transform: scale(1);
                    transition: 0.4s;
                }

                &:hover img {
                    transform: scale(1.2);
                    transition: 0.4s;
                }
            }

            .slick-slide {
                position: relative;

                .ticket-number {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: 900;
                    font-size: 64px;
                    line-height: 77px;
                    color: rgba(255, 255, 255, 0.6);
                    z-index: 3;
                    cursor: pointer;
                }

                .ticket-number.three {
                    font-size: 35px;
                    line-height: 35px;
                    width: 90%;
                    word-break: break-all;
                    max-width: 90%;
                    text-align: center;
                }

                .hexagone-list {
                    position: relative;
                    cursor: pointer;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.2);
                        content: '';
                        border-radius: 8px;
                    }
                }

                .hexagone-list.orange {
                    &::after {
                        background: rgba(252, 157, 1, 0.2);
                    }
                }

            }

            // .slick-slide.slick-active.slick-current .hexagone-list {
            //     &::after{ background: rgba(252, 157, 1, 0.2);  border-radius: 8px;                }
            // }
        }
    }
}

.step-header.step-component {
    .steps-left-sec {
        min-height: unset;
        background: transparent;
        padding: 0 !important;
        border: none;
    }

    .step-right-slider-sec {
        min-height: unset;
        background: transparent;
        padding: 0 !important;
        border: none;
    }
}


.steps {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    backdrop-filter: blur(12px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 20px;
    text-align: center;

    >span {
        color: #fff;
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 20px;
        font-weight: 400;
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-right: 30px;
    }

    ul {
        text-align: center;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 17px 0px;
        margin-bottom: 0px;

        li {
            margin: 0px 16px;
            cursor: pointer;

            div {
                padding: 9px 6px;
                position: relative;
                width: 26px;
                height: 26px;
                border-radius: 4px;

                &::after {
                    background: radial-gradient(50% 50% at 50% 50%, #BEBECD 0%, #8989A4 100%);
                    border-radius: 4px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: rotate(45deg);
                    content: '';
                }

                span {
                    position: relative;
                    z-index: 2;
                    color: #fff;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                    /* identical to box height, or 157% */
                    text-align: center;
                    color: #FFFFFF;
                    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

                    a {
                        cursor: pointer;
                        color: #FFF;
                        text-decoration: none
                    }
                }
            }
        }

        li.active,
        li.done {
            div {
                &::after {
                    background: #4caf1b;
                }
            }
        }

        li.activate {
            div {
                &::after {
                    background: #FCA801;
                }
            }
        }

        li.selected {
            div {
                &::after {
                    background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                }
            }
        }

        li.red {
            div {
                &::after {
                    background: radial-gradient(50% 50% at 50% 50%, #FE8EA0 0%, #FC002A 100%);
                }
            }
        }

        li.yellow {
            div {
                &::after {
                    background: radial-gradient(50% 50% at 50% 50%, #FCA801 65.1%, #FFCA61 100%);
                }
            }
        }
    }
}

.nori-last-step-box {
    position: relative;
    z-index: 2;
}

@media(min-width:1200px) {
    .stepper-sec.quickpick-sec {
        .step-component {
            .steps-left-sec {
                padding: 40px 80px 40px 120px;
            }
        }
    }

    .max-label {
        padding-right: 12px;
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .max-label {
        padding-right: 12px;
    }

    .stepper-sec {
        .step-component {
            .steps-left-sec {
                max-width: 640px;
                padding: 50px 30px;
                margin-bottom: 20px;
            }
        }

        .sidebar {
            top: 50px;

            li {
                a {
                    height: 70px;

                    span {
                        font-size: 9px;
                    }

                    img {
                        max-width: 30px;
                    }
                }
            }
        }

        .common-step-box {
            .nori-mandetory-ticket-sec {
                .nori-ticket-sec {
                    margin: 0px 10px;
                    min-height: 350px;

                    &::after {
                        right: 85%;
                        top: 3%;
                    }

                    &::before {
                        right: 80%;
                        bottom: 18%;
                    }
                }
            }

            .filter-list-sec {
                .filter-list-item {
                    .filter-left-sec {
                        width: 60%;
                    }
                }
            }
        }
    }

    .stepper-sec .step-component .steps-left-sec .generate-tickets-btns {
        a {
            .tooltiptext {
                display: none !important;
            }
        }
    }
}

@media(min-width:768px) and (max-width:991px) {
    .max-label {
        padding-right: 12px;
    }

    .stepper-sec {
        padding-left: 85px;

        .step-component {
            .steps-left-sec {
                max-width: 720px;
                padding: 50px 30px;
                min-height: unset;

                &::after {
                    backdrop-filter: blur(6px);
                    width: 80%;
                    left: 10%;
                }
            }

            .step-right-slider-sec {
                max-width: 720px;
                margin-left: 0;
                margin-top: 20px;
                min-height: unset;
                backdrop-filter: blur(12px);

                .slick-arrow.slick-next {
                    transform: rotate(0);
                    left: 98.5%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                }

                .slick-arrow.slick-prev {
                    transform: rotate(0);
                    left: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                }

                .custom-left-btn {
                    transform: rotate(0);
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                }

                .custom-right-btn {
                    transform: rotate(0);
                    left: 96.5%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                }
            }
        }

        .sidebar {
            top: 70px;

            li {
                a {
                    height: 70px;

                    span {
                        font-size: 9px;
                    }

                    img {
                        max-width: 30px;
                    }
                }
            }
        }

        .common-step-box {
            .nori-mandetory-ticket-sec {
                .nori-ticket-sec {
                    margin: 0px 10px;
                    min-height: 350px;

                    &::after {
                        right: 85%;
                        top: 3%;
                    }

                    &::before {
                        right: 80%;
                        bottom: 18%;
                    }
                }
            }

            .filter-list-sec {
                .filter-list-item {
                    .filter-left-sec {
                        width: 60%;
                    }
                }
            }
        }
    }

    .stepper-sec .step-component .steps-left-sec .generate-tickets-btns {
        a {
            .tooltiptext {
                display: none !important;
            }
        }
    }

    .stepper-sec .common-step-box .nori-mandetory-ticket-sec .nori-ticket-sec::before {
        bottom: 22%;
    }

    .quickpick-sec {
        .step-right-slider-sec {
            margin: 0 !important;
        }
    }

    .quickpick-sec.stepper-sec {
        .step-component {
            .steps-left-sec {
                margin-bottom: 20px;
            }
        }
    }

    .min-tab-unset {
        min-height: unset !important;
    }
}

@media (max-width:767px) {
    .stepper-sec {
        padding-left: 0;

        .blueballimg {
            top: 40%;
            transform: translateY(-40%);
        }

        .sidebar {
            top: 130px;
            width: 60px;
            z-index: 999;
            background: rgba(38, 38, 38, 1);
            position: fixed;

            li {
                height: 60px;
                position: relative;
                z-index: 999;

                a {
                    height: 60px;
                    position: absolute;
                    top: 0;
                    left: 0;

                    span {
                        font-size: 8px;
                    }

                    img {
                        max-width: 20px;
                    }
                }
            }
        }

        .mobile-sidebar-btn {
            z-index: 100;
        }

        .step-component {
            .steps-left-sec {
                max-width: 90%;
                padding: 50px 20px;
                min-height: unset;
                margin-bottom: 10px;
                padding-top: 65px;

                .self-config-list {
                    margin-top: 20px;

                    .col-md-6 {
                        &:nth-child(1) {
                            order: -1;
                        }

                        &:nth-child(3) {
                            order: 0;
                        }

                        &:nth-child(4) {
                            order: 1;
                        }

                        &:nth-child(7) {
                            order: 2;
                        }

                        &:nth-child(2) {
                            order: 3;
                        }

                        &:nth-child(4) {
                            order: 4;
                        }

                        &:nth-child(6) {
                            order: 5;
                        }

                        &:nth-child(8) {
                            order: 6;
                        }
                    }

                    .config-form-grp {
                        label {
                            font-size: 14px;
                        }
                    }
                }

                .nori-mandetory-ticket-sec {
                    .nori-ticket-sec {
                        margin: 0;
                        margin-top: 100px;

                        &::after {
                            top: -40px;
                            transform: rotate(90deg) translateX(-35%);
                            left: 35%;
                            width: 80px;
                            background-size: 100% auto;
                        }

                        &::before {
                            display: none;
                        }

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }

                .filter-list-sec {
                    .filter-list-item {
                        margin-bottom: 15px;

                        .filter-left-sec {
                            width: 100%;
                            margin-bottom: 10px;

                            span {
                                font-size: 14px;
                                line-height: 1.3;
                            }
                        }

                        .filter-right-sec {
                            .filter-select {
                                select {
                                    padding: 4px 35px 4px 12px;
                                }

                                &::after {
                                    right: 20px;
                                    top: 5px;
                                }
                            }

                            .filter-select.after-none {
                                display: none;
                            }
                        }

                        &:first-of-type {
                            display: none;
                        }
                    }
                }

                .nori-form-grp.nori-full-form {
                    padding: 10px 20px;

                    p {
                        font-size: 12px;
                        line-height: 16px;
                    }

                    input {
                        width: 50px;
                    }

                    .tooltiptext {
                        right: unset;
                        left: 0;
                    }
                }

                .nori-last-step-box {

                    .tooltiptext {
                        width: 250px;
                        max-width: 70vw;
                        right: unset;
                        left: 0;
                    }

                    .combination-icon {
                        >label{
                            display: flex;
                        }
                        .tooltiptext {
                            right: 0;
                            left: unset;
                        }
                    }
                }

                .nori-input-form-grp {
                    label {
                        font-size: 12px;
                        line-height: 16px;
                        min-height: 24px;
                        align-items: center;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .step-right-slider-sec {
                max-width: 90%;
                margin-left: 0;
                margin-top: 20px;
                min-height: unset;
                position: relative;

                &::after {
                    position: absolute;
                    top: 5px;
                    left: 5%;
                    width: 90%;
                    height: 50%;
                    content: '';
                    backdrop-filter: blur(5px);
                }

                .slick-arrow.slick-next {
                    transform: rotate(0);
                    left: 97%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 998;
                }

                .slick-arrow.slick-prev {
                    transform: rotate(0);
                    left: -3%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 998;
                }

                .custom-left-btn {
                    transform: rotate(0);
                    left: 1%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 99;
                }

                .custom-right-btn {
                    transform: rotate(0);
                    left: 95%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 99;
                }

                .slick-slider {
                    position: relative;
                    z-index: 1;
                }
            }
        }

        h1 {
            padding: 0;
            font-size: 30px;
        }
    }

    .steps {
        span {
            margin-right: 0;
            margin-bottom: 0px;
            font-size: 14px;
            cursor: pointer;
        }

        ul {
            width: 100%;

            li {
                margin: 0px 7px;

                div {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .stepper-sec .step-component .steps-left-sec .btn-component div input {
        font-size: 14px;
        &.buyLuncher{
            background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
            border: none;
            border-radius: 50px;
            transition: .1s;
            &:hover{
                transform: scale(1.05);
            }
        }
    }

    .common-step-box {
        p {
            font-size: 14px;
        }
    }

    .stepper-sec .step-component .steps-left-sec .generate-tickets-btns {
        flex-direction: column;
        align-items: center;

        a {
            margin-bottom: 20px;

            .tooltiptext {
                display: none !important;
            }
        }
    }

    .stepper-sec .common-step-box .nori-mandetory-ticket-sec .nori-ticket-sec ul li {
        margin-top: 2px;
    }

    .stepper-sec .common-step-box .nori-mandetory-ticket-sec .nori-ticket-sec ul {
        top: 50px;
        left: 30px;
    }

    .stepper-sec .common-step-box .nori-mandetory-ticket-sec .slick-arrow.slick-next {
        right: -20px;
        z-index: 999;
    }

    .stepper-sec .common-step-box .nori-mandetory-ticket-sec .slick-arrow.slick-prev {
        left: -20px;
        z-index: 999;
    }

    .stepper-sec .step-component .steps-left-sec::after {
        display: none;
    }

    .generation-completed span {
        font-size: 14px;
    }

    .stepper-sec .step-component .steps-left-sec .nori-form-grp.nori-full-form input.quickpick-input {
        margin-left: 10px;
    }

    .stepper-sec .step-component .steps-left-sec .note {
        font-size: 14px;
    }

    .sixth-step-box {
        .common-title {
            font-size: 19px;
        }
    }

}

@media(min-width:320px) and (max-width:360px) {
    .stepper-sec {
        .step-component {
            .step-right-slider-sec {
                .hexagone-list {
                    li {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    .stepper-sec .step-component .step-right-slider-sec .slick-arrow.slick-prev {
        left: -5%;
    }

    .sixth-step-box {
        .common-title {
            font-size: 15px;
        }
    }
}

@media(max-width:991px) {
    .generation-completed {
        img {
            animation-name: swipeBottom;
            right: 50%;
            transform: translate(50%) rotate(90deg);
            top: 25px;
        }
    }
}