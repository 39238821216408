@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url('../../../../node_modules/bootstrap/dist/css/bootstrap.css');

body {
  padding-top: 120px;
  font-family: "Inter", sans-serif;
  background: url("../../images/body-bg.jpg") no-repeat center center/cover;
  min-height: 100vh;
}

h1 {
  font-size: 48px;
  line-height: 1.3;
}

h3 {
  font-size: 32px;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  line-height: 28px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #417cff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0051ff;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-30 {
  margin-left: 10px !important;
}

.rdt_Pagination {
  z-index: 99;
}

.modal {
  background: rgba($color: #000000, $alpha: 0.8);
}

.blue-text {
  color: #2560E9;
}

.small-btn.common-white-btn {
  padding: 10px 16px;
  min-width: 136px;

  span {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
  }
}

.p-relative {
  position: relative;
  margin-bottom: 40px;

  .redballimg {
    position: absolute;
    top: 98%;
    right: 20%;
    position: absolute;
    z-index: -1;
    max-width: 100px;
  }

  .diceimg {
    position: absolute;
    top: 25%;
    left: -35px;
    position: absolute;
    z-index: 3;
    max-width: 60px;
  }
}

.text-right {
  text-align: right;
}

:root {
  --color-bg: #458;
  --color-switch-thumb: #8E96C2;
  --color-switch-bg: #CCD4FF;
  --color-switch-bg-active: #3F5DFF;

  --switch-size: 52px;
}

.switch-input {
  display: none;
}

.switch {
  --switch-width: var(--switch-size);
  --switch-height: 32px;
  --switch-border: calc(var(--switch-height) / 10);
  --switch-thumb-size: calc(var(--switch-height) - var(--switch-border) * 2);
  --switch-width-inside: calc(var(--switch-width) - var(--switch-border) * 2);
  display: block;
  box-sizing: border-box;
  width: var(--switch-width);
  height: var(--switch-height);
  border: var(--switch-border) solid var(--color-switch-bg);
  border-radius: var(--switch-height);
  background-color: var(--color-switch-bg);
  cursor: pointer;
  margin: var(--switch-margin) 0;
  transition: 300ms 100ms;

  position: relative;
}

.switch::before {
  content: '';
  background-color: var(--color-switch-thumb);
  height: var(--switch-thumb-size);
  width: var(--switch-thumb-size);
  border-radius: var(--switch-thumb-size);

  position: absolute;
  top: 0;
  left: 0;

  transition: 300ms, width 600ms;
}

.switch-input:checked+.switch {
  background-color: var(--color-switch-bg-active);
  border-color: var(--color-switch-bg-active);
}

.switch-input:checked+.switch::before {
  background: #fff;
}

.switch:active::before {
  width: 80%;
}

.switch-input:checked+.switch::before {
  left: 100%;
  transform: translateX(-100%);
}

.small-btn.common-blue-btn {
  padding: 10px 16px;
  min-width: 52px;

  span {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
  }
}

.common-white-btn {
  padding: 20px 40px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 64px;
  letter-spacing: -0.01em;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  min-width: 210px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    color: #2560E9;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
  }

  &:hover {
    background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
    color: #fff;

    span {
      color: #fff;
      background: transparent !important;
      -webkit-text-fill-color: unset;
    }
  }

  .tooltiptext {
    max-height: 150px;
    overflow-y: auto;
    display: none !important;
    text-align: left !important;
  }

  .tooltip-heading:hover {
    .tooltiptext {
      display: block !important;
    }
  }

  .tooltiptext:hover {
    display: block !important;
  }
}

.tooltiptext.tooltip-modal-text {
  right: 0;
  left: unset !important;
  top: unset !important;
  bottom: 100% !important;
  color: #fff !important;
}

.common-white-btn:hover span.tooltiptext {
  background: rgba(0, 0, 0, 0.8) !important;
}

.common-blue-btn {
  border: none;
  padding: 20px 40px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 64px;
  letter-spacing: -0.01em;
  background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
  cursor: pointer;
  min-width: 210px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    background: transparent !important;
    -webkit-text-fill-color: unset;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
  }

  &:hover {
    background: #fff;

    span {
      color: #2560E9;
    }
  }
}

.tooltip-heading {
  position: relative;
  cursor: pointer;

  &:hover .tooltiptext {
    display: inline-block;
  }

  .tooltiptext {
    padding: 8px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    background: rgba(0, 0, 0, 0.8) !important;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    position: absolute;
    top: 90%;
    left: 90%;
    width: max-content;
    max-width: 330px;
    z-index: 999;
    display: none;
    text-align: left !important;

    &:hover {
      display: block;
    }
  }
}

.mobile-sidebar-btn {
  display: none;
}

.tickets-numbers {
  border: none;
  padding: 10px 20px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  letter-spacing: -0.01em;
  background: #808080;
  cursor: pointer;
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

footer {
  padding: 16px 0px;
  background: #fff;
  color: #000a3d;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;

  ul.footer-links {
    padding: 0;
    margin: 0;
    display: flex;
    -webkit-display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    text-align: right;
    justify-content: flex-end;

    li {
      margin-right: 25px;
      position: relative;

      &::after {
        background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
        border-radius: 2px;
        position: absolute;
        width: 1px;
        height: 100%;
        content: "";
        top: 0;
        right: -12px;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #000a3d;
        text-decoration: none;
      }
    }
  }

  ul.social-icons {
    padding: 0;
    margin: 0;
    display: flex;
    -webkit-display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    text-align: center;
    justify-content: center;

    li {
      margin-right: 24px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      a {
        img {
          transform: scale(1);
        }

        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  p {
    margin: 0;
  }
}

.common-title {
  position: relative;
  color: #fff;
  display: inline-block;
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;

  .infoicon {
    margin-left: 8px;
  }

  &::after {
    position: absolute;
    top: 120%;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,
        rgba(73, 185, 255, 0) 0%,
        #49b9ff 29.17%,
        #3f5dff 71.35%,
        rgba(63, 93, 255, 0) 100%);
    border-radius: 8px;
    content: "";
    transition: 0.3s;
  }
}

.common-table-white-btn {
  padding: 3px 12px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24),
    0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 64px;
  letter-spacing: -0.01em;
  background: #fff;
  text-decoration: none;
  cursor: pointer;
  min-width: 220px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    color: #2560E9;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
  }

  &:hover {
    background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
    color: #fff;

    span {
      color: #fff;
      background: transparent !important;
      -webkit-text-fill-color: unset;
    }
  }
}

.use-a-nori-table {
  margin-bottom: 60px !important;
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 5%;
    width: 90%;
    height: 30%;
    content: '';
    backdrop-filter: blur(12px);
  }

  .common-table-sec {
    position: relative;
    z-index: 11;

    .rdt_Table {
      min-height: 300px;
    }

    .rdt_Pagination {
      svg {
        fill: #417cff
      }

      svg {
        transform: scale(1);
        transition: 0.4s;

        &:hover {
          transform: scale(1.2);
          transition: 0.4s;
        }
      }

    }

    ul {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .btn-component {
    position: relative;
    z-index: 1;

  }

  .redballimg {
    position: absolute;
    top: 98%;
    right: 20%;
    position: absolute;
    z-index: -1;
    max-width: 100px;
  }

}

.common-table-box-sec {
  width: 100%;
  max-width: 1140px;
  min-height: 511px;
  background: rgba(0, 0, 0, 0.4);
  padding: 50px 80px;
  position: relative;
  border-radius: 20px;
  border: 2px solid rgb(73, 185, 255);
  margin: 0px auto;
  color: #fff;
  margin-top: 30px;

  .btn-component {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 20px 0;

    div {
      position: relative;

      input {
        background: transparent;
        box-shadow: none;
        color: #fff;
        border: none;
        padding-right: 32px;
        cursor: pointer;
        z-index: 100;
        position: relative;
      }

      &::after {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 2px;
        right: 0;
        background: url('../../images/arrow-right.png')no-repeat center center/cover;
        content: '';
        z-index: 0;
      }

      &:first-of-type {
        input {
          padding-right: 5px;
          padding-left: 35px;
        }

        &::after {
          right: unset;
          left: 5px;
          background: url('../../images/arrow-left.png')no-repeat center center/cover;
          transform: scale(1);
          transition: 0.4s;
        }
      }

      &:hover::after {
        transform: scale(1.2);
        transition: 0.4s;
      }
    }
  }
}

.common-table-statisc.common-table-sec.sort {
  .rdt_TableHeadRow {
    // > div:nth-child(2) { min-width: 20px; flex-grow: 0;
    //   .rdt_TableCol_Sortable { position: absolute; right: -110px; z-index: 99; min-width: unset;
    //     div { display: none; min-width: unset; }
    //   }
    //  }
    //  > div:nth-child(3){ margin-left: -20px;

    //   }
  }
}

.common-table-statisc.common-table-sec.undrawn-table {
  //   .rdt_TableHeadRow{ justify-content: flex-start; text-align: left;
  //     > div:nth-child(2) {
  //       .rdt_TableCol_Sortable {  right: -160px; z-index: 99; min-width: unset;
  //     }
  //   }
  //   > div:nth-child(3) { padding-right: 30px;
  //     > div { max-width: 160px !important; }
  //   }
  // }
}

// .common-table-statisc {
//   .rdt_TableHeadRow {
//     > div:nth-child(1) { min-width: 100px; }
//   }
// }
// .parity-table.common-table-statisc{
//   .rdt_TableHeadRow {
//     > div:nth-child(1) { min-width: 250px; }
//   }
// }
.common-table-sec {
  .rdt_Pagination {
    border-top: 1px solid rgba(20, 121, 184, 0.8) !important;
  }
}

.common-table-statisc.common-table-sec.sort.undrawn-table-sec {
  // .rdt_TableHeadRow{
  //   > div:nth-child(2){
  //     .rdt_TableCol_Sortable { right: -150px; }
  //   }
  //   > div:nth-child(3){ max-width: 170px; min-width: 170px; }
  // }
}

.my-games-table {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;

  input {
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid rgb(73, 185, 255);
  }

}

.common-table-sec {
  margin-top: 5px;
  min-height: 200px;

  .loader-container {
    .loader {
      max-width: 40px;
    }
  }


  div {
    background-color: transparent;
    color: #fff;
    padding: 0;
    border: none !important;
  }

  .rdt_TableHeadRow {
    border-top: 1px solid rgba(20, 121, 184, 0.8) !important;
    border-bottom: 1px solid rgba(20, 121, 184, 0.8) !important;

    .rdt_TableCol {
      div {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .rdt_TableCell {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0px -7px;
    padding: 5px;

    li {
      background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
      transform: rotate(-45deg);
      padding-left: 2px;
      border-radius: 4px;
      width: 22px;
      height: 22px;
      margin: 10px 8px;

      span {
        transform: rotate(45deg);
        font-weight: 500;
        display: block;
        margin-top: 3px;
        margin-right: 2px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .rdt_Pagination {
    background: transparent;
    color: #fff;
    margin-top: 20px;

    svg {
      fill: #fff;
    }

    button:disabled,
    button[disabled] {
      opacity: 0.4;
    }
  }

  .use-btn {
    background: linear-gradient(133.31deg, #49b9ff 0%, #3f5dff 100%);
    border-radius: 64px;
    padding: 3px 12px;
    display: inline-flex;
    letter-spacing: -0.01em;
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
  }

  .preview-btn {
    text-decoration: none;
    background: #ffffff;
    border-radius: 64px;
    padding: 3px 12px;
    display: inline-flex;
    cursor: pointer;

    span {
      color: #2560E9;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.common-table-sec.sort {}

.widget-container {
  display: flex;
  color: #fff;
}

.widget-container>* {
  height: 400px;
  width: 50%;
  padding: 10px;
}

.d-mobile {
  display: none;
}

.d-desktop {
  display: block;
}

.canvasjs-chart-toolbar {
  display: none;
}

.chart-inner-sec {
  position: relative;
  margin-top: 40px;
  max-width: 100%;

  &::after {
    position: absolute;
    bottom: 12px;
    left: -25px;
    background: #242424;
    width: 100px;
    height: 30px;
    content: "";
    display: none;
  }

  &::before {
    position: absolute;
    bottom: -1px;
    right: 0;
    background: #242424;
    width: 150px;
    height: 30px;
    content: "";
    z-index: 99;
    display: none;
  }
}

.sidebar {
  position: absolute;
  top: 45px;
  left: 0;
  background: rgba(38, 38, 38, 0.8);
  border-radius: 0px 36px 36px 0px;
  padding: 0;
  width: 75px;
  z-index: 998;
  cursor: pointer;

  li {
    &:first-child {
      a {
        border-radius: 0px 36px 0px 0px;
        cursor: default;
      }
    }

    &:first-child:hover {
      a {
        background: rgba(38, 38, 38, 0.8);
      }
    }

    &:last-child {
      a {
        border-radius: 0px 0px 36px 0px;
        font-size: 10px;
      }
    }

    a {
      width: 100%;
      height: 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.01em;

      color: #ffffff;
      font-size: 10px;
      line-height: 18px;
      font-weight: 600;
      text-decoration: none;

      span {
        text-align: center;

        span {
          display: block;
        }
      }
    }

    &:hover {
      a {
        background: radial-gradient(64.38% 64.38% at 35.13% 21.75%,
            rgba(73, 185, 255, 0.5) 0%,
            rgba(61, 90, 255, 0.1) 100%);
      }
    }

    .active {
      background: radial-gradient(64.38% 64.38% at 35.13% 21.75%,
          rgba(73, 185, 255, 0.5) 0%,
          rgba(61, 90, 255, 0.1) 100%);
    }
  }
}

.range-slider-sec {
  position: relative;
  z-index: 99;
  color: #fff;
  margin-bottom: 60px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  div {
    outline: none !important;

    div {
      div {
        background: #fff !important;
        border-radius: 9px;
      }
    }

    &:focus {
      background: #fff !important;
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    li {
      min-width: 20px;
      text-align: center;
    }
  }
}

.stack-range.range-slider-sec {
  margin-top: 16px;
  margin-bottom: 16px;

  div {
    outline: none !important;
    border-radius: 24px;

    div {
      background: #fff !important;
      border-radius: 9px;
    }
  }
}

.statics-main-sec {
  .range-slider-sec {
    ul {
      li {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15) {
          text-align: left;
        }

        &:nth-child(30),
        &:nth-child(31),
        &:nth-child(32),
        &:nth-child(33),
        &:nth-child(34),
        &:nth-child(35),
        &:nth-child(36),
        &:nth-child(37),
        &:nth-child(38),
        &:nth-child(39),
        &:nth-child(40) {
          text-align: right;
        }
      }
    }
  }
}

.chart-section {
  .range-slider-sec {
    ul {
      li {
        min-width: 30px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          text-align: left;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          text-align: center;
        }

        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          text-align: right;
        }
      }
    }
  }
}

// .build-nori-sec .nori-middle-sec .nori-right-sec .slick-slider .slick-track { display: flex; width: 100% !important; justify-content: center; }
.drag-sec {
  position: relative;
  display: inline-block;

  .dustbin-sec {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    div {}

  }

  .dustbin-sec.active {
    z-index: 9;

    div {}
  }

  .drag-item-list {
    position: relative;
    z-index: 6;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0px -2px;
    width: 100%;
    transform: translate(-50%, -50%);

    div {
      cursor: pointer !important;
      display: inline-block;
      width: 22px;
      line-height: 22px;
      background: radial-gradient(50.07% 41.07% at 44.64% 41.07%, rgba(255, 255, 255, 0.342) 0%, rgba(220, 212, 212, 0.122906) 56.96%, rgba(0, 0, 0, 0.437) 100%), #FFFFFF;
      border-radius: 50%;
      position: relative;
      font-size: 12px;
      line-height: 22px;
      margin: 0px 2px;
      font-weight: 500;
      border: 1px solid rgb(73, 185, 255);
      margin-top: 1.5px;
      color: rgb(0, 10, 61);
      border: 1px solid rgb(73, 185, 255) !important;

      &:nth-of-type(5)+br {
        width: 100%;
        display: block;

        &::before {}
      }

      &:nth-of-type(11)+br {
        display: block;
      }

      &:nth-of-type(18)+br {
        display: block;
      }

      &:nth-of-type(26)+br {
        display: block;
      }

      &:nth-of-type(33)+br {
        display: block;
      }

      &:nth-of-type(39)+br {
        display: block;
      }

      a {
        text-decoration: none;
        color: #000A3D;
      }
    }

    div.active {
      background: radial-gradient(50% 50% at 50% 50%, #DBF0D1 0%, #54FC01 100%) !important;
      position: relative;
      z-index: -1;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 99;
      }
    }

    div.red {
      background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FC002A 100%) !important;
      position: relative;
      z-index: -1;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 99;
      }
    }

    div.yellow {
      background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FCA801 100%) !important;
      cursor: pointer !important;
    }

    div.disabled {
      background: radial-gradient(50% 50% at 50% 50%, #F4F4F5 0%, #B9B9C6 100%) !important;
      opacity: 0.8;
      color: rgba(0, 10, 61, 0.6) !important;
    }

    br {
      display: block;
      width: 100%;
      display: none;
    }

    div {
      border: none;
      background: radial-gradient(50.07% 41.07% at 44.64% 41.07%, rgba(255, 255, 255, 0.342) 0%, rgba(220, 212, 212, 0.122906) 56.96%, rgba(0, 0, 0, 0.437) 100%), #FFFFFF !important;
      padding: 0 !important;
      margin: 0px 2px !important;
      float: unset !important;
      display: inline-block;
      border: 1px solid rgb(73, 185, 255) !important;
    }

    div.disabled {
      border: 1px solid #EAEAEE !important;
      color: rgba(0, 10, 61, 0.5);
      position: relative;
      z-index: -1;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 99;
      }
    }
  }
}

.error-sec {
  min-height: 90vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;

  .common-white-btn {
    margin-top: 25px;
    font-weight: 700;
  }

  img {
    max-width: 100%;
  }

  h1 {
    color: #4C9CF2 !important;
    margin-bottom: 24px !important;
    text-transform: capitalize !important;
  }

  p {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 22px;
  }
}

.live-drawing-sec .sidebar,
.statics-tabs-sec .sidebar,
.winning-sec .sidebar {
  top: 1px;
}

// Responsive CSS

@media(max-width:1199px) {
  .chart-inner-sec {
    overflow-x: auto;
    overflow-y: hidden;

    >div {
      min-width: 750px;
    }
  }
}

@media (min-width: 1600px) {
  p {
    font-size: 18px;
  }

  .small-btn.common-white-btn {
    padding: 14px 20px;

    span {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .small-btn.common-blue-btn {
    padding: 14px 20px;

    span {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body {
    padding-top: 70px;
  }

  h1 {
    font-size: 42px;
  }

  h3 {
    font-size: 28px;
    line-height: 34px;
  }

  h4 {
    font-size: 22px;
    line-height: 26px;
  }

  h5 {
    font-size: 18px;
    line-height: 22px;
  }

  .common-blue-btn {
    padding: 15px 25px;
    min-width: unset;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .common-white-btn {
    padding: 15px 25px;
    min-width: unset;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .small-btn.common-white-btn {
    padding: 12px 20px;
    min-width: unset;
  }

  .small-btn.common-blue-btn {
    padding: 12px 20px;
    min-width: unset;
  }

  footer {
    ul.footer-links {
      li {
        margin-right: 10px;

        a {
          font-size: 10px;
        }

        &:after {
          right: -5px;
        }
      }
    }
  }

  .tooltip-heading {
    position: relative;

    .tooltiptext {
      left: unset;
      right: 0;
    }
  }

  .sidebar {
    top: 50px;

    li {
      a {
        height: 70px;

        span {
          font-size: 9px;
        }

        img {
          max-width: 30px;
        }
      }
    }
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    padding-top: 70px;
  }

  h1 {
    font-size: 38px;
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .common-blue-btn {
    padding: 15px 20px;
    min-width: 180px !important;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .common-white-btn {
    padding: 15px 20px;
    min-width: 180px !important;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .small-btn.common-white-btn {
    min-width: 15px !important;
  }

  .small-btn.common-blue-btn {
    min-width: 15px !important;
  }

  footer {
    ul.footer-links {
      li {
        margin-right: 7px;

        a {
          font-size: 9px;
        }

        &:after {
          right: -3px;
          height: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    p {
      font-size: 12px;
    }
  }

  .tooltip-heading {
    position: relative;

    .tooltiptext {
      left: unset;
      right: 0;
    }
  }

  .sidebar {
    top: 70px;

    li {
      a {
        height: 70px;

        span {
          font-size: 9px;
        }

        img {
          max-width: 30px;
        }
      }
    }
  }

  .use-a-nori-table {
    padding: 30px !important;
  }

  .use-a-nori-table .btn-component {
    left: 20px !important;
  }
}

@media (max-width: 767px) {
  body {
    padding-top: 70px;
  }

  h1 {
    font-size: 38px;
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .mb-20 {
    margin-bottom: 0px !important;
  }

  .common-blue-btn {
    padding: 15px 30px;
    min-width: unset;

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .common-white-btn {
    padding: 15px 30px;
    min-width: unset;

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .generate-tickets-btns {
    .common-white-btn .tooltiptext {
      right: -80px;
      max-width: 300px;
      font-size: 10px;
    }
  }

  .error-sec p {
    font-size: 14px !important;
  }

  .chart-inner-sec {
    &::after {
      bottom: -3px;
    }

    &::before {
      bottom: -7px;
    }
  }

  .settings-popup {
    h4.modal-content {
      .tickPaddTop{
        margin-bottom: 20px !important;
      }
      .common-white-btn,
      .common-blue-btn {
        margin-bottom: 10px !important;
      }
    }
  }

  .small-btn.common-white-btn {
    padding: 15px 30px;
    min-width: unset;

    span {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0 !important;
    }
  }

  .small-btn.common-blue-btn {
    padding: 15px 20px;
    min-width: unset;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }

  footer {
    ul.footer-links {
      justify-content: center;

      li {
        margin-right: 12px;

        a {
          font-size: 9px;
        }

        &:after {
          right: -6px;
          height: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    p {
      font-size: 12px;
      text-align: center;
    }
  }

  .tooltip-heading {
    position: relative;

    .tooltiptext {
      left: unset;
      right: -15px;
      max-width: 250px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .d-mobile {
    display: block;
  }

  .d-desktop {
    display: none;
  }

  .mobile-sidebar-btn {
    display: flex;
    position: fixed;
    top: 130px;
    left: 0;
    transition: 0.3s;
    z-index: 100;

    a {
      width: 60px;
      height: 60px;
      background: #191919;
      border-radius: 0px 24px 24px 0px;
      padding: 10px 12px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transition: 0.3s;
        transform: rotate(0);
      }
    }
  }

  .sidebar {
    top: 130px;
    width: 70px;

    li {
      a {
        display: inline-flex;
        height: 60px;

        span {
          margin-top: 2px;
          font-size: 9px;
        }

        img {
          max-width: 27px;
          max-height: 40px;
        }
      }

      &:last-child {
        a {
          span {
            margin-bottom: 3px;
            font-size: 8px;
            line-height: 1.5;
            text-align: center;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  .sidebar {
    left: -100px !important;
    top: 130px !important;
    transition: 0.3s;
    z-index: 9999;
    position: fixed;
    background: rgba(38, 38, 38, 0.9);
  }

  .sidebar.sidebar-open {
    left: 0 !important;
    transition: 0.3s;
  }

  .sidebar.sidebar-open+.mobile-sidebar-btn {
    left: 60px !important;
    transition: 0.3s;

    img {
      transform: rotate(-180deg);
      transition: 0.3s;
    }
  }

  .sidebar li:first-child a {
    background: #191919;
    border-radius: 0 !important;

    img {
      max-width: 30px;
    }
  }

  .common-table-sec .rdt_TableHeadRow .rdt_TableCol div {
    font-size: 14px;
    line-height: 20px;
    overflow: visible;
  }

  .iSAVrt {
    min-height: 35px;
  }

  .use-a-nori-table {
    padding: 20px !important;
    max-width: 90%;

    // .rdt_TableCell { min-width: 200px; }
    .redballimg {
      max-width: 80px;
    }
  }

  .common-table-box-sec {
    .btn-component {
      position: relative;
      z-index: 2;
    }
  }

  .use-a-nori-table .btn-component {
    left: 0px !important;
    padding: 0;
    padding-bottom: 20px;
  }

  .undrawn-table.common-table-sec {
    .rdt_TableHeadRow {
      .rdt_TableCol div {
        font-size: 12px;
      }
    }

    .rdt_TableCol_Sortable {
      span {
        margin-right: 15px;
        z-index: 999;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 360px) {

  .small-btn.common-white-btn,
  .small-btn.common-blue-btn {
    padding: 12px 16px;
  }
}

.horizontal-slider {
  width: 100%;
  height: 50px;
}

.handle {
  width: 30px;
  height: 30px;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.26);
  background-color: #fff;
  border-radius: 100%;
  transition: all 300ms ease-in-out;
}

.handle.active {
  background-color: grey;
}

.bar {
  position: relative;
  background: #ebe7e7;
  transition: all 300ms ease-in-out;
}

.bar.bar-1 {
  background-image: linear-gradient(to left, black, white);
  transition: all 300ms ease-in-out;
}

.bar.bar-2 {
  background: #ebe7e7;
}

.horizontal-slider .bar {
  top: 20px;
  height: 10px;
}

.horizontal-slider .handle {
  top: 1px;
  width: 40px;
  height: 40px;
  line-height: 48px;
}

.slider__labels {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  position: relative;
  height: 30px;
}

.slider__labels-label {
  text-align: center;
  width: 40px;
  font-weight: bold;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid rgb(209, 208, 208);
  font-size: 11px;
  border-radius: 5px;
}

// .modal-dialog { max-width: 100% !important; }