.home-banner-sec {
    padding-top: 50px;
    margin-top: -50px;
    background: url('../../images/home-banner-bg.png')no-repeat center center/cover;
    min-height: 95vh;
    padding-left: 75px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    .sidebar {
        top: 50px;
    }

    .banner-scroll-sec {
        position: absolute;
        top: calc(100% - 60px);
        left: 0;
        width: 100%;
        height: 120px;
        z-index: -1;
    }

    .home-banner-middle-sec {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        .banner-common-box-sec {
            margin: 0px 40px;
            max-width: 320px;
            min-width: 280px;
            color: #FFFFFF;
            text-align: center;
            border-radius: 20px;
            border: 2px solid rgba(73, 185, 255, 0.8);
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(12px);
            min-height: 415px;

            .banner-common-box-padding {
                padding: 40px 35px;

                .winning-list {
                    list-style-type: none;
                    padding: 0;
                    text-align: left;
                    margin: 0;

                    li {
                        margin-bottom: 10px;
                        text-align: center;

                        img {
                            margin-right: 16px;
                        }

                        span {
                            letter-spacing: -0.01em;
                            color: #FFFFFF;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }

                h4 {
                    display: inline-block;
                    position: relative;
                    margin-bottom: 20px;
                    font-weight: 600;

                    &:before {
                        position: absolute;
                        top: 120%;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(90deg, rgba(73, 185, 255, 0) 0%, #49B9FF 29.17%, #3F5DFF 71.35%, rgba(63, 93, 255, 0) 100%);
                        border-radius: 8px;
                        content: '';
                    }
                }

                h3 {
                    margin-bottom: 0px;
                    background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                    &:nth-child(3) {
                        background: rgb(181, 181, 181);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        margin-bottom: 10px;
                    }
                }

                .pool-list {
                    text-align: left;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        position: relative;
                        margin-bottom: 10px;
                        padding-left: 26px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::after {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 16px;
                            height: 16px;
                            background: url('../../images/star-icon.svg')no-repeat center center/cover;
                            content: '';
                        }
                    }
                }
            }
        }

        .banner-left-box {
            display: flex;
            background: url('../../images/banner-ad-bg.png')no-repeat center center/cover;
            border: none;

            .banner-common-box-padding {
                width: 100%;

                .slick-slide {
                    height: 100%;
                    display: flex;

                    h4 {
                        background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 26px;
                        line-height: 32px;
                        max-width: 90%;
                    }

                    div {
                        height: 100%;
                        width: 100%;
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-align: left;
                        max-height: 220px;
                        overflow-y: auto;

                        li {
                            margin-bottom: 15px;
                            position: relative;
                            padding-left: 26px;
                            display: inline-block;
                            color: #001334;

                            span {
                                color: #001334;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                            }

                            &::after {
                                position: absolute;
                                top: 4px;
                                left: 0;
                                width: 16px;
                                height: 16px;
                                background: url('../../images/star-icon.svg')no-repeat center center/cover;
                                content: '';
                            }
                        }
                    }
                }

                .slick-track {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .banner-dice-sec {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                text-decoration: none;

                &:hover {
                    color: #3b9dd9;
                }
            }

            .dice-box-header {
                position: relative;
                margin-bottom: 0;

                h5 {
                    padding: 10px 30px;
                    position: relative;
                    z-index: 1;
                    color: #fff;
                    margin: 0;
                }

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    background: linear-gradient(90deg, rgba(73, 185, 255, 0), #49b9ff 44.17%, #3f5dff 78.35%, rgba(63, 93, 255, 0));
                    opacity: 0.5;
                    border-radius: 8px;
                    text-align: center;
                }
            }

            h3 {
                color: white;
                margin-bottom: 0;
                font-weight: 700;
                text-align: center;
            }

            .dice-img {
                margin-bottom: 20px;
                width: 90%;
                max-width: 400px;
            }
        }

        .next-draw-timer-sec {
            position: relative;
            padding: 10px 20px;

            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: radial-gradient(64.38% 64.38% at 35.13% 21.75%, #49B9FF 0%, #3F5DFF 100%)
                    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
                ;
                opacity: 0.35;
                backdrop-filter: blur(40px);
                border-radius: 0px 0px 18px 18px;
                -webkit-border-radius: 0px 0px 18px 18px;
                content: '';
            }

            .next-draw-sec {
                position: relative;
                z-index: 1;

                p {
                    margin-bottom: 5px;
                }

                ul {
                    list-style-type: none;
                    margin: 0px -16px;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    justify-content: center;

                    li {
                        margin: 0px 16px;
                        position: relative;

                        &:after {
                            position: absolute;
                            width: auto;
                            height: auto;
                            font-size: 40px;
                            line-height: 40px;
                            color: #fff;
                            content: ':';
                            top: -5px;
                            right: -20px;
                        }

                        &:last-child:after {
                            display: none;
                        }

                        h3 {
                            margin: 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 13px;
                            letter-spacing: 0.02em;
                            text-transform: capitalize;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }

        .right-common-box {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-end;

            &::after {
                width: 32px;
                height: 32px;
                background: url('../../images/star-img.svg')no-repeat center center/cover;
                top: 25px;
                right: -17px;
                content: '';
                position: absolute;
            }

            &::before {
                width: 32px;
                height: 32px;
                background: url('../../images/star-img.svg')no-repeat center center/cover;
                bottom: 70px;
                left: -17px;
                content: '';
                position: absolute;
            }
        }
    }

    .banner-footer {
        margin: 0px auto;
        padding: 40px 60px;
        max-width: 800px;
        background: url('../../images/banner-footer.png')no-repeat top center/cover;

        h4 {
            color: #fff;
            text-align: center;
            max-width: 80%;
            margin: 0px auto;
        }
    }
}

.build-nori-sec {
    padding: 64px 40px 60px;
    color: #FFFFFF;

    h1 {
        margin-bottom: 24px;
        background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-transform: uppercase;
        font-weight: 700;
    }

    p {
        text-align: center;
    }

    .nori-common-sec {
        border: 2px solid rgb(73, 185, 255);
        background: rgba($color: #000000, $alpha: 0.4);
        border-radius: 20px;
        max-width: 465px;
        padding: 32px 40px;
        margin-bottom: 26px;
        backdrop-filter: blur(12px);

        .nori-config-sec {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;

            p {
                margin-bottom: 24px;
            }

            a {
                margin-bottom: 24px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .other-nori-btns {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            a {
                margin-right: 24px !important;
                margin-bottom: 5px;

                &:last-of-type {
                    margin-right: 0px !important;
                }
            }

            >span {
                margin-bottom: 5px;
            }

            .tickets-numbers {
                margin-left: 15px;
                display: contents;
                font-size: 16px;
                color: #aa9e9e;
                font-weight: bold;
                line-height: 19px;
            }
        }

        .hexagone-list {
            padding: 0;
            list-style-type: none;
            max-width: 320px;
            display: flex;
            flex-wrap: wrap;
            border: 2px solid rgba(73, 185, 255, 0.8);
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.8);
            padding: 20px;
            margin: 0px auto;
            cursor: pointer;
            position: relative;

            li {
                width: 36px;
                height: 36px;
                background: #fff;
                color: #000;
                border-radius: 4px;
                float: left;
                margin-top: -6px;
                -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                display: flex;
                align-items: center;
                justify-content: center;

                &:nth-child(7n+1) {
                    margin-left: 17px;
                    clear: both;
                }

                &:nth-child(14n+1) {
                    margin-left: 0px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }

            li.active {
                background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                color: #fff;
            }

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                content: '';
                border-radius: 8px;
            }

            .ticket-number {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-weight: 900;
                font-size: 64px;
                line-height: 77px;
                color: rgba(255, 255, 255, 0.9);
                z-index: 1;
            }

            .ticket-number-large.ticket-number {
                font-size: 120px;
            }

            .ticket-number-large.ticket-number.three {
                font-size: 50px;
                line-height: 50px;
                width: 90%;
                word-break: break-all;
                max-width: 90%;
                text-align: center;
            }
        }

        .hexagone-list.orange {
            &::after {
                background: rgba(252, 157, 1, 0.2);
            }
        }

        .nori-number-slider {}

        .thumbnail-slider-wrap {
            margin-top: 10px;
            margin-bottom: 40px;
            position: relative;

            .hexagone-list {
                max-width: 120px;
                padding: 10px;

                li {
                    width: 12px;
                    height: 12px;
                    margin-top: -1px;
                    font-size: 8px;
                    line-height: 8px;

                    &:nth-child(7n+1) {
                        margin-left: 7px;
                        clear: both;
                    }

                    &:nth-child(14n+1) {
                        margin-left: 0px;
                    }
                }
            }

            .slick-arrow.slick-prev {
                bottom: 30%;
                transform: translateY(-30%);
                left: -20px;
                z-index: 99;
                top: unset;
                background: url('../../images/arrow-left.png')no-repeat center center/100% auto;

                &::before {
                    content: '';
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    top: 0;
                    left: 0;
                }
            }

            .slick-arrow.slick-next {
                bottom: 30%;
                transform: translateY(-30%);
                right: -20px;
                z-index: 99;
                top: unset;
                background: url('../../images/arrow-right.png')no-repeat center center/100% auto;

                &::before {
                    content: '';
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    top: 0;
                    left: 0;
                }
            }

            .custom-left-btn {
                bottom: 25%;
                transform: translateY(-25%);
                left: -25px;
                z-index: 99;
                top: unset;
                position: absolute;
                padding: 0;
                background: transparent;
                border: none;

                img {
                    transform: scale(1);
                    transition: 0.4s;
                }

                &:hover img {
                    transform: scale(1.2);
                    transition: 0.4s;
                }
            }

            .custom-right-btn {
                bottom: 25%;
                transform: translateY(-25%);
                right: -25px;
                z-index: 99;
                top: unset;
                position: absolute;
                padding: 0;
                background: transparent;
                border: none;

                img {
                    transform: scale(1);
                    transition: 0.4s;
                }

                &:hover img {
                    transform: scale(1.2);
                    transition: 0.4s;
                }
            }

            .slick-slide {
                position: relative;
                outline: none !important;

                div {
                    outline: none !important;
                }

                .ticket-number {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: 900;
                    font-size: 64px;
                    line-height: 77px;
                    color: rgba(255, 255, 255, 0.9);
                    z-index: 1;
                    cursor: pointer;
                }

                .ticket-number.three {
                    font-size: 30px;
                    line-height: 30px;
                    width: 90%;
                    word-break: break-all;
                    max-width: 90%;
                    text-align: center;
                }

                .hexagone-list {
                    position: relative;
                    cursor: pointer;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.2);
                        content: '';
                        border-radius: 3px;
                    }
                }

                .hexagone-list.orange {
                    &::after {
                        background: rgba(252, 157, 1, 0.2);
                    }
                }

                &:focus {
                    outline: none !important;
                }
            }

            .slick-current.slick-slide {
                ul {
                    border-width: 4px;
                }
            }

            .noTicket {
                text-align: center;
                position: absolute;
                bottom: -22px;
                width: 100%;
            }

            // .slick-slide.slick-active.slick-current .hexagone-list {
            //     &::after{ background: rgba(252, 157, 1, 0.2);  border-radius: 8px; left: 0px; width: 100%; }
            // }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .nori-middle-sec {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;

        .nori-left-sec {
            position: relative;
            padding: 0px 30px;

            .greenballimg {
                position: absolute;
                top: 20px;
                left: -76px;
                max-width: 120px;
            }

            .redballimg {
                position: absolute;
                bottom: 140px;
                right: -20px;
                z-index: 1;
                max-width: 120px;
            }

            max-width: 460px;
            width: 100%;
        }

        .nori-right-sec {
            position: relative;
            z-index: 2;

            .blueballimg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -70px;
                z-index: 0;
                max-width: 100px;
            }

            max-width: 460px;
            width: 100%;

            .nori-common-sec {
                position: relative;
                z-index: 1;
                height: 100%;
            }
        }

        .nori-common-sec.config-sec {
            min-height: 320px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .per-ticket-text {
            margin-bottom: -15px;
            margin-top: 15px;
            opacity: 0.8;
            font-style: italic;
            font-weight: 500;
        }
    }
}

.banner-common-box-sec {
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, .5);
    border: 2px solid var(--bs-cyan);
    border-radius: 20px;
    position: relative;
    color: var(--bs-white);
    margin: 0 40px;
    max-width: 320px;
    min-height: 415px;
    min-width: 280px;
    text-align: center;
}

.abcd {
    position: absolute;
    width: 100%;
    height: 100%;
    color: var(--bs-white);
    padding: 40px;
    text-align: center;
    transition: 2s;
    opacity: 0;
    clip-path: circle(1.1% at 51% 0);
    top: 0;
    left: 0;
}

.abcd.active {
    clip-path: circle(150% at 51% 0);
    transition: 2s;
    opacity: 1;
    transition-property: clip-path;
}

.home-banner-middle .banner-common-box-sec .abcd h4 {
    display: block;
    height: 10%;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    border-bottom: 1px solid --bs-gray;
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(90deg, transparent, var(--bs-gray), transparent);
    border-image-slice: 1;
}

.home-banner-middle-sec .banner-common-box-sec .abcd ul {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.banner-common-box-list-sec li {
    color: var(--bs-white);
    font-size: 16px;
    font-weight: 1000;
    letter-spacing: -.01em;
    line-height: 24px;
    list-style: none;
}

.banner-common-box-lith1 li {
    font-family: "Times New Roman", Times, serif;
    color: var(--bs-primary);
}

.banner-common-box-lith2 li {
    font-family: Arial, Helvetica, sans-serif;
    color: var(--bs-gray-100);
}

.banner-common-box-lith3 li {
    font-family: 'Courier New', Courier, monospace;
    color: var(--bs-gray-400);
}

.banner-common-box-lith4 li {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: var(--bs-light);
}

.banner-common-box-lith5 li {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--bs-white);
}

.banner-common-box-lith6 li {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: var(--bs-gray-400);
}

.banner-common-box-lith7 li {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: var(--bs-primary);
}

.banner-common-box-lith8 li {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: var(--bs-gray-900);
}

.banner-common-box-lith9 li {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--bs-white);
}

.banner-common-box-lith10 li {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: var(--bs-primary);
}

.banner-dice-sec {
    height: 411px;
}

@media (min-width:1200px) and (max-width:1600px) {
    .home-banner-sec .banner-footer {
        padding: 30px 60px;
    }

    .home-banner-sec .home-banner-middle-sec .banner-dice-sec .dice-img {
        margin-bottom: 10px;
    }

    .home-banner-sec .home-banner-middle-sec .banner-dice-sec .dice-img {
        max-width: 350px;
    }

    .banner-dice-sec {
        text-align: center;
    }

    .home-banner-sec .home-banner-middle-sec .banner-common-box-sec .banner-common-box-padding {}
}

@media (min-width:1600px) {
    .home-banner-sec {
        .home-banner-middle-sec {

            // .banner-common-box-sec{
            //   min-width: 400px;  max-width: 450px;
            // }
            .banner-dice-sec {
                // .dice-img { min-width: 500px; }
            }
        }

        .banner-footer {
            margin-top: 50px;
        }
    }

    .build-nori-sec {
        .nori-middle-sec {
            // .nori-left-sec { max-width: 600px; }
            // .nori-right-sec { max-width: 600px; }
            // .nori-common-sec { max-width: 600px;}
        }

        .nori-common-sec {
            .other-nori-btns {
                justify-content: center;
            }
        }
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .home-banner-sec {
        .sidebar {
            top: 75px;
        }

        .home-banner-middle-sec {
            .banner-common-box-sec {
                min-width: 200px;
                max-width: 240px;
                margin: 0px 20px;

                .banner-common-box-padding {
                    padding: 25px;
                }
            }

            .banner-dice-sec {
                max-width: 360px;

                .dice-box-header {
                    text-align: center;
                }

                .dice-img {
                    max-width: 100%;
                }
            }

            .next-draw-timer-sec {
                .next-draw-sec {
                    ul {
                        margin: 0px -7px;

                        li {
                            margin: 0px 7px;

                            &::after {
                                right: -12px;
                                top: -5px;
                                font-size: 30px;
                                line-height: 30px;
                            }

                            h3 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .build-nori-sec {
        padding: 34px 20px 30px;

        .nori-common-sec {
            .thumbnail-slider-wrap {
                margin-bottom: 22px;
            }
        }
    }

    .build-nori-sec .nori-middle-sec .nori-common-sec.config-sec {
        min-height: 300px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .home-banner-sec {
        .sidebar {
            top: 75px;
        }

        .home-banner-middle-sec {
            .banner-common-box-sec {
                max-width: 300px;
                margin: 0px 20px;
                min-height: unset;

                .banner-common-box-padding {
                    padding: 25px;
                }
            }

            .banner-dice-sec {
                margin-bottom: 80px;
                max-width: 100%;
                width: 100%;
                order: -1;
                text-align: center;

                .dice-box-header {
                    text-align: center;
                    display: inline-block;
                }

                .dice-img {
                    max-width: 100%;
                    margin-bottom: 10px;
                    max-width: 400px;
                }
            }

            .next-draw-timer-sec {
                padding: 15px 20px 15px;

                .next-draw-sec {
                    ul {
                        margin: 0px -7px;

                        li {
                            margin: 0px 7px;
                            min-width: 50px;
                            text-align: center;

                            &::after {
                                right: -10px;
                                font-size: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }

        .banner-footer {
            margin-top: 40px;
        }
    }

    .build-nori-sec {
        padding: 34px 20px 30px;

        .nori-middle-sec {
            .nori-left-sec {
                .greenballimg {
                    max-width: 80px;
                    left: -30px;
                }

                .redballimg {
                    max-width: 80px;
                }
            }
        }
    }

    .nori-common-sec {
        .thumbnail-slider-wrap {
            margin-bottom: 15px !important;
        }
    }

    .nori-common-sec.other-nori-sec {
        margin-bottom: 30px !important;
    }

    .build-nori-sec .nori-middle-sec .nori-common-sec.config-sec {
        min-height: unset;
    }
}

@media(max-width:767px) {
    .home-banner-sec {
        padding: 0;
        padding-top: 50px;

        .home-banner-middle-sec {
            margin: 0;

            .banner-common-box-sec {
                margin: 0px 20px;
                margin-bottom: 25px;
                order: 2;
                min-height: unset;

                .banner-common-box-padding {
                    padding: 25px;
                }
            }

            .banner-common-box-sec.right-common-box {
                order: 1;
            }

            .banner-dice-sec {
                max-width: 100%;
                width: 100%;
                order: -1;
                text-align: center;
                margin-bottom: 40px;
                height: auto;

                .dice-box-header {
                    text-align: center;
                    margin-top: 25px;
                }

                .dice-img {
                    max-width: 80%;
                    margin-bottom: 10px;
                    margin-top: 60px;
                    margin-bottom: 50px;
                }
            }

            .next-draw-timer-sec {
                .next-draw-sec {
                    ul {
                        margin: 0px -7px;

                        li {
                            margin: 0px 7px;
                            min-width: 50px;
                            text-align: center;

                            &::after {
                                right: -10px;
                                font-size: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }

        .banner-footer {
            margin-bottom: 40px;
            order: -1;
            padding: 20px 10px;
            background-size: 100% 100%;
        }
    }

    .build-nori-sec {
        padding: 34px 10px 30px;

        .nori-middle-sec {
            .nori-left-sec {
                .redballimg {
                    right: 0;
                    max-width: 60px;
                    z-index: -1;
                }

                .greenballimg {
                    left: -30px;
                    max-width: 70px;
                }
            }

            .nori-right-sec {
                .blueballimg {
                    right: 0;
                }
            }

            .nori-common-sec {
                padding: 15px 25px;
                margin-bottom: 30px;
                backdrop-filter: none;

                .hexagone-list {
                    max-width: 250px;

                    .ticket-number-large.ticket-number {
                        font-size: 90px;
                    }

                    .ticket-number-large.ticket-number.three {
                        font-size: 50px;
                    }

                    li {
                        width: 27px;
                        height: 27px;
                        margin-top: -4px;

                        &:nth-child(7n+1) {
                            margin-left: 13px;
                        }

                        &:nth-child(14n+1) {
                            margin-left: 0px;
                        }
                    }
                }

                .thumbnail-slider-wrap {
                    margin-bottom: 20px !important;

                    .hexagone-list {
                        max-width: 125px;

                        li {
                            width: 12px;
                            height: 12px;
                            margin-top: 0;

                            &:nth-child(7n+1) {
                                margin-left: 8px;
                            }

                            &:nth-child(14n+1) {
                                margin-left: 0px;
                            }
                        }
                    }

                    .custom-left-btn {
                        left: -15px;
                    }

                    .custom-right-btn {
                        right: -15px;
                    }
                }

                .thumbnail-slider-wrap+.other-nori-btns {
                    flex-direction: row;

                    a {
                        margin: 0px 10px;
                        margin-bottom: 20px;

                        &:last-of-type {
                            margin-bottom: 20px;
                        }
                    }

                    .tickets-numbers {
                        margin-bottom: 20px;
                        margin-top: 0;
                    }
                }

                .other-nori-btns {
                    justify-content: center;
                    flex-direction: column;

                    a {
                        margin-bottom: 24px;
                        margin-right: 0 !important;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .tickets-numbers {
                        margin-left: 0;
                        margin-top: 20px;
                        text-align: center;
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }

            .nori-common-sec.other-nori-sec {
                position: relative;

                .nori-config-sec {
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 100%;
                    height: 100%;
                    content: '';
                    border-radius: 20px;
                    backdrop-filter: blur(5px);
                }
            }
        }
    }

    .nori-middle-sec {
        .per-ticket-text {
            margin-top: 0 !important;
            margin-bottom: -25px !important;
        }
    }

    .stepper-sec .step-component .steps-left-sec::after {
        display: block !important;
        content: '' !important;
        bottom: 1px !important;
        height: 20% !important;
    }

    .tooltip-heading .tooltiptext {
        background: rgba(0, 0, 0, 1);
    }

    .stepper-sec .step-component .steps-left-sec .btn-component {
        z-index: 1;
    }

    .build-nori-sec .nori-middle-sec .nori-common-sec.config-sec {
        min-height: unset;
    }

    .home-banner-sec .home-banner-middle-sec .banner-left-box {
        max-width: 280px;
    }
}

@media(max-height:770px) {
    .home-banner-sec {
        .home-banner-middle-sec {
            margin-top: 10px;
        }
    }
}

@media(min-width:320px) and (max-width:360px) {
    .build-nori-sec {
        .nori-common-sec {
            .other-nori-btns {
                a {}
            }
        }
    }
}

.off {
    display: none;
}

.homeModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 9999;
    backdrop-filter: blur(10px);

    >div {
        min-width: 200px;
        max-width: 600px;
        margin: 30px;
        padding: 20px;
        border: 2px solid var(--bs-cyan);
        border-radius: 10px;
        background-color: #0c0b18;
        text-align: center;

        img {
            width: 100px;
        }

        h3 {
            font-size: 40px;
            color: gray;
        }

        button {
            width: 150px;
            height: 40px;
            border: none;
            margin: 0 5px;
            border-radius: 50px;
            cursor: pointer;
            background: var(--bs-cyan);
            font-weight: 500;
            font-size: 20px;

            :hover {
                background-color: #fff;
            }

            &.no {
                background-color: #b5b5b5;

                &:hover {
                    background-color: #fff;
                }
            }

            &.yes {
                background-color: #ff2323;

                &:hover {
                    background-color: #b20000;
                }
            }
        }
    }
}
.modalBuyConnect{
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: calc(100% - 85px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 999;
    backdrop-filter: blur(10px);
    cursor: default;
    >.fleche{
        position: absolute;
        right: 180px;
        top: 0;
        height: 50%;
        z-index: 2;
        
    }
}

.notConnectedBG { 
    position: relative; 
    display: inline-block; 
    >img{
        position: absolute;
        &.blue{
            z-index: 3;
            width: 50%;
            top: -100px;left: -70px;
        }
        &.red{
            z-index: 1;
            width: 100%;
            right: 0;
            top: -175px;
            left: 25%;
            filter: blur(4px);
        }
        &.green{
            width: 40%;
            top: 0;
            left: 5%;
            z-index: 2;
        }
    }
    >div{
        position: relative;
        z-index: 4;
        height: 3px;
    }
    .message{
        background-color: black;
        border: 2px solid #3b9dd9;
        border-radius: 15px;
        min-height: 150px;
        min-width: 330px;
        width: 500px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 7;
        h3{
            border-radius: 50px;
            padding: 12px;
            text-align: center;
        }
        button{
            display: flex;
            padding: 10px;
            border-radius: 15px;
            border: none;
            outline: none;
            color: white;
            font-weight: 700;
            transition: all .1s ease-in-out;
            margin-bottom: 10px;
            &.connect{
                background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                img{
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
                &:hover{
                    background: linear-gradient(133.31deg, #3F5DFF 0%, #49B9FF 100%);
                    transform: scale(1.05);
                }
            }
            &.cancel{
                border: 2px solid red;
                color: red;
                background-color: transparent;
                &:hover{
                transform: scale(1.05);
                }
            }
        }
    }
 }
.modalBuy {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 9999;
    backdrop-filter: blur(10px);
    .transparent{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    h1 {
        color: var(--bs-cyan);
        font-weight: 700;
    }

    >.content {
        min-width: 200px;
        max-width: 600px;
        margin: 30px;
        padding: 20px;
        border: 2px solid var(--bs-cyan);
        border-radius: 10px;
        background-color: #0c0b18;
        text-align: center;
        z-index: 2;
    }

    .total,
    .competition {
        font-size: 20px;
    }

    .buy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    button {
        width: 260px;
        border: none;
        margin: 10px;
        padding: 0 15px;
        border-radius: 15px;
        cursor: pointer;
        background: var(--bs-cyan);
        font-weight: 500;
        font-size: 20px;

        span {
            color: white;
            font-size: 25px;
            display: inline-block;
        }

        &.cancel {
            border: 1px solid red;
            background-color: transparent;
            color: red;
            width: 150px;
        }
    }
}
.chainlink-banner {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    a {
        width: 80%;
        min-width: 330px;
        max-width: 720px;
    }
}
@media(max-width:768px) {
    .modalBuyConnect .fleche{
        right: 0px;
    }
    .modalBuy .content{
        margin: 1%;
        padding: 3%;
    }
}
@media(max-width:1200px) {
    .modalBuyConnect{
        top: 60px;
        height: calc(100% - 60px);
    }
}