.modal-dialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) !important;
    max-width: 550px !important;
    margin: 0 !important;
    min-width: 345px;
    .modal-content {
        border: 2px solid rgb(73, 185, 255);
        background: rgba(0, 0, 0, 0.8);
        min-height: 225px;  padding: 18px 24px;position: relative;
        max-height: 70vh !important;
        overflow-y: auto;
        .modal-body {
            padding: 0; position: unset; color: #fff;
            .ticket-detail-sec { display: flex; flex-wrap: wrap;
                .ticket-left-sec {
                    .hexagone-list { padding: 0; list-style-type: none; max-width: 320px; display: flex; flex-wrap: wrap; background: url('../../images/border-img-dark.png')no-repeat center center/95% 100%; padding: 20px;
                        li { cursor: pointer; width: 36px; height: 36px; background: #fff; color: #000;
                            border-radius: 4px; float: left; margin-top: -6px;
                            -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                            clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%); display: flex; align-items: center; justify-content: center;
                            &:nth-child(7n +1){
                                margin-left: 17px;
                                clear: both;
                            }
                            &:nth-child(14n +1){
                                margin-left: 0px;
                            }
                            &:first-child { margin-left: 0;}
                            a {
                                text-decoration: none;color: #000;
                            }
                        }
                        li.active { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); color: #fff;
                            a {
                                color: #fff;
                            }
                        }
                    }
                }
                .ticket-right-sec {
                    .ticket-btns-list { display: flex; flex-direction: column; list-style-type: none; padding: 0;
                         li { margin-bottom: 8px; cursor: pointer;
                            div {background: #1F1F1F; padding: 12px 8px;
                                box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.4), 0px 64.8148px 58.5648px rgba(0, 0, 0, 0.303704), 0px 38.5185px 31.8519px rgba(0, 0, 0, 0.242963), 0px 20px 16.25px rgba(0, 0, 0, 0.2), 0px 8.14815px 8.14815px rgba(0, 0, 0, 0.157037), 0px 1.85185px 3.93519px rgba(0, 0, 0, 0.0962963);
                                border-radius: 8px;
                                display: flex; flex-direction: column;font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                text-align: center;
                                color: #FFFFFF; align-items: center; justify-content: center; text-decoration: none;
                                img { max-width: 20px; text-align: center; margin-bottom: 6px; }
                            }
                         }
                    }
                }
            }
            p{text-align: center;}
            .ticket-footer-sec { display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;
                .remianin-tickets { padding: 3px 12px 0px 12px;
                    background: rgba(0, 0, 0, 0.8);
                    box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.4), 0px 64.8148px 58.5648px rgba(0, 0, 0, 0.303704), 0px 38.5185px 31.8519px rgba(0, 0, 0, 0.242963), 0px 20px 16.25px rgba(0, 0, 0, 0.2), 0px 8.14815px 8.14815px rgba(0, 0, 0, 0.157037), 0px 1.85185px 3.93519px rgba(0, 0, 0, 0.0962963);
                    border-radius: 20px;border: 1px solid #3F5DFF;
                    font-size: 14px;line-height: 22px;text-align: center;color: #FFFFFF;
                    span { display: inline-block;font-size: 14px;line-height: 22px;text-align: center;color: #FFFFFF; margin-right: 8px;
                        &:last-child { margin-right: 0; margin-left: 8px; }
                        strong  { margin-right: 5px; }
                    }
                    strong { color: rgb(65, 124, 255);}


                }
                .confirmation-btn {
                    a { display: inline-block; margin-right: 16px;
                        &:last-child { margin-right: 0;}
                    }
                }
            }
        }
    }
}

.common-close-btn { background: transparent; border: none; box-shadow: none; position: fixed; top: 20px; right: 20px; z-index: 99;
    img { max-width: 28px;  }
}
.deleteTicket{
    background-color: transparent;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: transform .2s;
    >div{
        padding-left: 10px;
        button{
            cursor: pointer;
            width: 40px;
            border: none;
            outline: none;
            border-radius: 10px;
            margin: 0 2px 0 2px;
        }
        :nth-child(1){
            background-color: greenyellow;
            transition: background-color .2s;
            &:hover{
                background-color: green;
                transform: scale(1.2);
            }
        }
        :nth-child(2){
            background-color: red;
            transition: background-color .2s;
            &:hover{
                background-color: rgb(172, 1, 1);
                transform: scale(1.2);
            }
        }
    }
}

@media(max-width:767px){
    .modal-dialog {max-width: 85% !important;
        .modal-content {
            .modal-body {
                .ticket-detail-sec { display: flex; flex-wrap: wrap; justify-content: center;
                    .ticket-left-sec {
                        .hexagone-list { max-width: 250px;
                            li { width: 27px; height: 27px; margin-top: -4px;
                                &:nth-child(7n +1){
                                    margin-left: 13px;
                                }
                                &:nth-child(14n +1){
                                    margin-left: 0px;
                                }
                            }
                        }
                    }
                    .ticket-right-sec {
                        .ticket-btns-list { flex-direction: row; flex-wrap: wrap; justify-content: center; margin: 0; max-width: 210px;
                            li { margin-bottom: 10px; margin-right: 5px; margin-left: 5px; width: 100%;
                                min-width: 110px;
                            }
                        }
                    }
                }
                .ticket-footer-sec { justify-content: center; align-items: center; flex-direction: column;
                    .remianin-tickets { margin-bottom: 20px; margin-top: 10px; }
                }
            }
        }
    }
}