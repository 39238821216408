 .range-slider-sec.statics-range.stack-range { margin-bottom: 0; max-width: 100%; display: flex; flex-wrap: wrap; align-items: flex-end;
        div {
            div {background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%) !important;
                div { background: #fff !important; }
            }
        }
        ul { margin-bottom: 0; }
        > div { flex-grow: 1; }
        > span { font-size: 14px; line-height: 20px; margin-left: 10px; background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%); margin-bottom: 2px; font-weight: 700;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; display: inline-block; }
    }
.stacking-reward-header-sec { background: #0D0D0D; color: #fff; padding: 40px 0px; position: relative;
    .container { display: flex; align-items: center; justify-content: space-between;

    }

    .stacking-header-left { width: 60%; padding-right: 60px; position: relative;
        &::after { position: absolute; top: 0; right: 0; width: 2px; height: 100%; background: linear-gradient(145deg, rgba(73, 185, 255, 0) 0%, #49b9ff 29.17%, #3f5dff 71.35%, rgba(63, 93, 255, 0) 100% ); border-radius: 8px; content: ""; transition: 0.3s; }
        p {font-size: 18px;line-height: 28px; margin-bottom: 15px; }
        ul { list-style-type: none; padding: 0; margin: 0; margin-bottom: 16px;
            li { padding-left: 26px; margin-bottom: 8px; position: relative;
                &::before { position: absolute; top: 4px;left: 0; width: 16px; height: 16px; background: url('../../images/star-img.svg')no-repeat center center/100% auto; content: ''; }
                strong { background: radial-gradient(50% 50% at 50% 50%, #FFCA61 0%, #FCA801 65.1%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; }
            }
        }
        span { font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: #FFFFFF;
            opacity: 0.8; font-style: italic; }

    }
    .stacking-header-right { width: 40%; position: relative; padding-left: 60px;
        h1 { font-weight: 700; margin-bottom: 20px; }
        p { font-weight: 400;font-size: 20px;line-height: 32px; margin-bottom: 0; max-width: 70%;
            a { background: radial-gradient(50% 50% at 50% 50%, #FFCA61 0%, #FCA801 65.1%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent; font-weight: 700; font-size: 28px;line-height: 32px; position: relative;
                &::after { position: absolute; top: 100%;left: 0; width: 100%; height: 2px; background: radial-gradient(50% 50% at 50% 50%, #FFCA61 0%, #FCA801 65.1%); content: ''; }
            }
        }
    }
    &::before { position: absolute; bottom: 100%; left: 0; width: 100%; height: 300px; background: #0D0D0D; content: '';  }
}
.stacking-main-sec { padding: 50px 0px; min-height: unset; overflow: hidden;
    .stacking-tab-sec { min-height: unset; max-width: 100%; border-width: 2px; position: relative; overflow: visible;
        .redballimg { position: absolute; top: 98%; right: 20%; position: absolute; z-index: -1; max-width: 100px; }
    }
    .nora-ticket-list { max-width: 90%; margin: 20px auto;
        .deposite-nora-ticket { min-height: 114px; }
        .nora-ticket-form-sec {  display: flex; align-items: center; width: 100%; }
        .nora-ticket    { background: #1F1F1F; margin-bottom: 16px;
            border-radius: 8px;padding: 16px 24px;
            .range-slider-sec{
                ul {
                    li {
                        &:nth-child(3) { padding-left: 12px; }
                     }
                }
            }
            .tooltip-heading { margin-right: 10px; vertical-align: top; opacity: 1; }
            label { font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                span {background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; }
            }
            h1 { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent; font-weight: 700; }
            form { width: 100%;
                .form-grp { display: flex; }
                input { padding: 11px 16px 11px 12px;border: 1px solid #49B9FF;
                    background: rgba(0, 0, 0, 0.8);
                    border-radius: 8px; color: #FFFFFF; text-align: right; flex-grow: 1;
                    opacity: 0.5; font-style: italic; outline: none; }
                button { padding: 8px 15px; background: #FFFFFF; margin-left: 24px; border: none;
                    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24), 0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06);
                    border-radius: 64px;
                    span {  background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        display: inline-block;
                        text-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24), 0px 28px 52px rgba(0, 0, 0, 0.16), 0px 16px 24px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.06); font-weight: 500; font-size: 14px;
                        line-height: 24px; }
                }
            }
        }
        .nora-switch-sec { display: flex; justify-content: space-between;
            p { font-size: 16px;
                line-height: 19px;
                text-transform: capitalize;
                font-style: italic;
                color: rgba(255, 255, 255, 0.7);
                 margin-bottom: 10px; }
            .switch { margin-bottom: 10px; }
        }
        .deposit-btn { font-weight: 700; margin-top: 24px; text-transform: capitalize; }
    }
    .claim-sec { background: #1F1F1F; padding: 20px;
        border-radius: 8px;  min-height: 256px; margin-bottom: 16px;
        display: flex; flex-wrap: wrap; justify-content: center; align-items: center; flex-direction: column;
        .active-stack-header { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 30px; width: 100%;
            h6 { margin: 0; }
            select {  padding: 8px 20px 7px 12px;  border: 2px solid rgba(73, 185, 255, 0.8); border-radius: 8px;  cursor: pointer;
                color: #fff;
                background: #100f18;
            margin-left: 5px;
                outline: none;
                position: relative; z-index: 1;
                font-size: 14px;
                &:focus { outline: none;  }
            }
        }
        .active-stack-list { padding: 0; list-style-type: none; width: 100%; text-align: left;
            li { padding-left: 26px; margin-bottom: 8px; position: relative; font-size: 14px;
                &::before { position: absolute; top: 4px;left: 0; width: 16px; height: 16px; background: url('../../images/star-img.svg')no-repeat center center/100% auto; content: ''; }
                strong { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; font-size: 16px; }
            }
        }
        img { margin-bottom: 12px; }
        h6 { font-size: 24px; line-height: 32px; margin-bottom: 8px;
            strong { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent; }
        }
        p { font-size: 18px;
            line-height: 28px; }
        .daily-autocomplete-sec { padding: 11px 24px;background: rgba(0, 0, 0, 0.4);
            border-radius: 56px; width: 85%; margin: 0px auto;margin-bottom: 20px;
            h4 { display: flex; flex-wrap: wrap; justify-content: space-between; margin: 0; }
        }
        h4 { font-weight: 600; }
        .claim-btns-sec { width: 85%; margin: 0px auto;text-transform: capitalize; margin-top: 24px; display: flex; justify-content: center;
            a.common-white-btn {  padding: 11px 40px;  min-width: unset;  }
            a.common-blue-btn { padding: 11px 40px; margin-left: 16px; min-width: unset;  }
        }
        .green-title { background: linear-gradient(133.31deg, #C5FF49 0%, #18B130 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; }
        .green-btn.common-blue-btn { margin-top: 24px; background: linear-gradient(133.31deg, #C5FF49 0%, #18B130 100%);
            span { color: #191919; }
        }
    }
    .referral-tab-sec { padding:10px;
        ul { list-style-type: none; padding: 0; margin: 0; margin-bottom: 16px;
            li { padding-left: 26px; margin-bottom: 8px; position: relative;
                &::before { position: absolute; top: 4px;left: 0; width: 16px; height: 16px; background: url('../../images/star-img.svg')no-repeat center center/100% auto; content: ''; }
                strong { background: radial-gradient(50% 50% at 50% 50%, #FFCA61 0%, #FCA801 65.1%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; }
            }
        }
        .nora-ticket-list { max-width: 100%; }
        .refral-right-sec { position: relative;
            img { width: 100%; }
            .refral-right-top-sec { position: absolute; top: 40px; color: #000A3D; padding: 0px 10px; text-align: center; width: 100%;
                h5 { font-weight: 600;
                    font-size: 20px;
                    line-height: 24px; margin-bottom: 16px; }
                h1 { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;font-weight: 700;
                    font-size: 40px;
                    line-height: 48px; margin-bottom: 5px; }
                    h2 { color: #171A2C;font-weight: 700;
                        font-size: 40px;
                        line-height: 48px; }
            }
            .common-blue-btn { position: absolute; bottom: 80px; left: 50%; transform: translateX(-50%); margin-top: 0; font-weight: 700; }
        }
        .my-referal-sec {
            h3 { font-weight: 700;
                font-size: 24px;
                line-height: 28px; margin-bottom: 35px; margin-top: 24px;
                span { background: linear-gradient(133.31deg, #49B9FF 0%, #3F5DFF 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; display: inline-block; margin-left: 15px; }
            }
            .my-refral-heading { margin-bottom: 16px; padding: 8px 20px; border-top: 1px solid rgba(20, 121, 184, 0.8); border-bottom: 1px solid rgba(20, 121, 184, 0.8); }
            .refral-list-sec { padding: 0px 20px;
                ul { max-height: 240px; overflow-y: auto; margin: 0; }
            }
         }
    }
}
.disabled { position: relative;
    &::after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: ''; z-index: 999; }
    > * { opacity: 0.5; }
 }
@media(min-width:992px) and (max-width:1199px){
    .stacking-reward-header-sec .stacking-header-left { padding-right: 30px; }
    .stacking-reward-header-sec .stacking-header-right {  padding-left: 30px; }
    .stacking-main-sec .referral-tab-sec .refral-right-sec .common-blue-btn { width: 90%; }
    .stacking-main-sec .stacking-tab-sec {
        .claim-sec {
             min-height: 295px;
        }
        .active-stack-header {
            h6 { font-size: 20px; width: 100%; margin-bottom: 10px; }
            select { padding: 5px 20px 5px 10px; font-size: 12px; margin-left: 0; }
        }
    }
    .stacking-main-sec .nora-ticket-list .nora-ticket form button { min-width: 150px; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form input { width: 150px; font-size: 14px; }
}

@media(min-width:768px) and (max-width:991px){
    .stacking-reward-header-sec .container { flex-wrap: wrap; }
    .stacking-reward-header-sec .stacking-header-left { padding-right: 0px; width: 100%;margin-bottom: 40px;
        &::after { width: 100%; height: 2px; top: calc(100% + 20px);  }
    }
    .stacking-reward-header-sec .stacking-header-right {  padding-left: 0px; width: 100%; }
    .stacking-main-sec .nora-ticket-list { max-width: 100%; margin: 20px auto; }
    .stacking-main-sec .stacking-tab-sec { max-width: 100% !important;
        .active-stack-header {
            h6 { font-size: 20px; }
            select { padding: 5px 20px 5px 10px; font-size: 12px; }
        }
    }
    .stacking-reward-header-sec .stacking-header-right p { font-size: 22px; }
    .stacking-main-sec.statics-tabs-sec .nav-tabs .nav-item .nav-link { text-transform: capitalize; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form .form-grp { flex-wrap: wrap; text-align: center; justify-content: center; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form button { min-width: 150px; width: 100%; max-width: 200px; margin-left: 0; margin-top: 15px; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form input { width: 100%; font-size: 14px; }
    .stacking-main-sec .nora-ticket-list .deposite-nora-ticket { min-height: 135px; }
}
@media(max-width:767px){
    .stacking-reward-header-sec .container { flex-wrap: wrap; }
    .stacking-reward-header-sec .stacking-header-left { padding-right: 0px; width: 100%;margin-bottom: 40px;
        &::after { width: 100%; height: 2px; top: calc(100% + 20px);  }
    }
    .stacking-reward-header-sec .stacking-header-right {  padding-left: 0px; width: 100%; }
    .stacking-main-sec .nora-ticket-list { max-width: 100%; margin: 20px auto; }
    .stacking-reward-header-sec .stacking-header-left p,
    .stacking-reward-header-sec .stacking-header-left ul li,
    .stacking-reward-header-sec .stacking-header-left span,
    .stacking-reward-header-sec .stacking-header-right p,
    .stacking-reward-header-sec .stacking-header-right p a { font-size: 14px; line-height: 20px; max-width: 100%; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form .form-grp { flex-wrap: wrap; }
    .stacking-main-sec .nora-ticket-list .nora-ticket form button {  margin-left: auto; margin-right: auto; margin-top: 20px; padding: 8px 12px; }
    .stacking-main-sec .stacking-tab-sec { padding: 15px !important; }
    .stacking-main-sec .nora-ticket-list .nora-switch-sec { flex-direction: column; align-items: flex-start; }
    .stacking-main-sec .nora-ticket-list .nora-switch-sec .switch { margin-top: 10px; }
    .stacking-main-sec .nora-ticket-list .nora-ticket { text-align: left !important; }
    .stacking-main-sec .text-right { text-align: left !important; }
    .stacking-main-sec .nora-ticket-list .nora-switch-sec p {margin-bottom: 10px !important; }
    .stacking-main-sec .justify-content-end { justify-content: flex-start !important; align-items: flex-start !important; }
    .stacking-main-sec .claim-sec .daily-autocomplete-sec h4 { font-size: 18px;  align-items: center; justify-content: center;
        div { margin-left: 10px; }
    }
    .stacking-main-sec .claim-sec .claim-btns-sec { width: 80%; flex-direction: column; margin-bottom: 20px; margin-top: 10px;
        a.common-blue-btn { margin-left: 0; margin-top: 10px; }
    }
    .stacking-main-sec .claim-sec .daily-autocomplete-sec { width: 100%; margin-top: 20px; }
    .stacking-main-sec .referral-tab-sec .refral-right-sec { margin-top: 20px; }
    .stacking-main-sec .referral-tab-sec .refral-right-sec .common-blue-btn { width: 80%; }
    .stacking-main-sec .referral-tab-sec .my-referal-sec .refral-list-sec { padding: 0; }
    .stacking-main-sec .referral-tab-sec .my-referal-sec h3 span { margin-left: 0; width: 100%; margin-top: 5px; }
    .stacking-reward-header-sec .stacking-header-right p a { display: inline-block; }
    .stacking-tabs { overflow-x: auto; overflow-y: hidden;
        .nav-tabs{ min-width: 480px;
            li { min-width: 150px; }
    }
    }
    .stacking-main-sec .claim-sec .active-stack-header { align-items: center;
        h6 { font-size: 20px;  }
    }
    }